import { takeEvery, put, all, call } from 'redux-saga/effects';
import { WebSiteTypes } from '../../actions/actions.types';
import * as actions from '../../actions/index';
import axios from '../../../config/axios/axios-instance';
import { headerContentTypeJson } from '../../../config/auth/authentication';
import { stringError } from '../../../utils/error/apiErrorMsg';
import { webSiteViewMsg } from '../../../config/view-msg/sagas.view-msg';
import { LINK_LIST_DOMAIN, LINK_LIST_PAGE_DOMAIN_SHARE, LINK_ADD_CONTENT_PAGE_SHARE } from '../../../routes';


export function* getListDomainSaga() {
    try {
        const response = yield axios.get('/api/gestion-website/list-domain');

        yield put(actions.getListDomainSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemDomainSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-website/domain/${id}`);
        yield put(actions.getItemDomainSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deleteDomainSaga(action) {
    try {
        const id = action.payload;
        yield axios.delete(`/api​/gestion-website/domain/${id}`);
        yield put(actions.deleteDomainSuccess(id));
        yield put(actions.alertNotificationStart(webSiteViewMsg['successDeleteDomain'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postDomainSaga(action) {
    try {
        const { formData, history } = action.payload;

        const response = yield axios.post('/api/gestion-website/create-domain', formData, headerContentTypeJson());

        yield put(actions.editDomainSuccess(response.data));
        yield put(actions.alertNotificationStart(webSiteViewMsg['successCreateDomain'], 'success'));

        yield call([history, history.push], LINK_LIST_DOMAIN);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* updateDomainSaga(action) {
    try {
        const { id, formData, history } = action.payload;
        const response = yield axios.put(`/api/gestion-website/domain/${id}`, formData, headerContentTypeJson());
        yield put(actions.editDomainSuccess(response.data));
        yield put(actions.alertNotificationStart(webSiteViewMsg['successUpdateDomain'], 'success'));

        yield call([history, history.push], LINK_LIST_DOMAIN);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getPagesDomainSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-website/domain/${id}/pages`);
        yield put(actions.getPagesDomainSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getPagesDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getPagesSermonDomainSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-website/domain/${id}/pages-sermon`);
        yield put(actions.getPagesSermonDomainSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getPagesSermonDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getListPageSaga() {
    try {
        const response = yield axios.get('/api/gestion-website/list-page');
        yield put(actions.getListPageSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListPageFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* searchPagesSaga(action) {
    const paramQuery = action.payload;
    try {
        const response = yield axios.get('/api/gestion-website/list-page', paramQuery);
        yield put(actions.searchPagesSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.searchPagesFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getListPageSermonSaga() {
    try {
        const response = yield axios.get('/api/gestion-website/list-page-sermon');
        yield put(actions.getListPageSermonSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListPageSermonFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* searchPagesSermonSaga(action) {
    const paramQuery = action.payload;
    try {
        const response = yield axios.get('/api/gestion-website/list-page-sermon', paramQuery);
        yield put(actions.searchPagesSermonSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.searchPagesSermonFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemPageSaga(action) {
    try {
        const { id, typePage } = action.payload;
        let response = ''
        if (typePage === 'sermon-page') {
            response = yield axios.get(`/api/gestion-website/page-sermon/${id}`);
        } else {
            response = yield axios.get(`/api/gestion-website/page/${id}`);
        }
        yield put(actions.getItemPageSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemPageFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postPageSaga(action) {
    try {
        const { typePage, formData, history, idDomain } = action.payload;
        let response = '';
        if (typePage === 'sermon-page') {
            response = yield axios.post(`/api/gestion-website/create-page-sermon`, formData, headerContentTypeJson());
        } else {
            response = yield axios.post(`/api/gestion-website/create-page`, formData, headerContentTypeJson());
        }
        yield put(actions.editPageSuccess(response.data));
        yield put(actions.alertNotificationStart(webSiteViewMsg['successCreatePage'], 'success'));

        let urlListPageDomain = `${LINK_LIST_PAGE_DOMAIN_SHARE}/${idDomain}`
        if (response.data && response.data['typePage'] && response.data['id']) {
            const typeForUrl = (response.data['typePage'].indexOf('/') > -1) ? response.data['typePage'].replace('/', '-') : response.data['typePage'];
            urlListPageDomain = `${LINK_ADD_CONTENT_PAGE_SHARE}/${typeForUrl}/${response.data['id']}`;
        }
        yield call([history, history.push], urlListPageDomain);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editPageFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* updatePageSaga(action) {
    try {
        const { id, typePage, formData, history, idDomain } = action.payload;
        let response = '';
        let msgSuccess = '';
        if (typePage === 'sermon-page') {
            response = yield axios.put(`/api/gestion-website/page-sermon/${id}`, formData, headerContentTypeJson());
            msgSuccess = webSiteViewMsg['successUpdatePage'];

        } else if (typePage === 'sermon-page-content') {
            response = yield axios.put(`/api/gestion-website/page-sermon-content/${id}`, formData, headerContentTypeJson());
            msgSuccess = webSiteViewMsg['successEditContentPage'];

        } else if (typePage === 'classic-page-content') {
            response = yield axios.put(`/api/gestion-website/page-content/${id}`, formData, headerContentTypeJson());
            msgSuccess = webSiteViewMsg['successEditContentPage'];

        } else {
            response = yield axios.put(`/api/gestion-website/page/${id}`, formData, headerContentTypeJson());
            msgSuccess = webSiteViewMsg['successUpdatePage'];
        }
        yield put(actions.editPageSuccess(response.data));
        yield put(actions.alertNotificationStart(msgSuccess, 'success'));

        if (typePage === 'classic-page-content' || typePage === 'sermon-page-content') {
            const urlListPageDomain = `${LINK_LIST_PAGE_DOMAIN_SHARE}/${idDomain}`;
            yield call([history, history.push], urlListPageDomain);
        } else if (typePage === 'sermon-page') {
            yield put(actions.getPagesSermonDomainStart(idDomain));
        } else {
            yield put(actions.getPagesDomainStart(idDomain));
        }
    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editPageFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deletePageSaga(action) {
    try {
        const { id, typePage } = action.payload;

        if (typePage === 'sermon-page') {
            yield axios.delete(`/api/gestion-website/page-sermon/${id}`);
            yield put(actions.deletePageSermonSuccess(id));

        } else {
            yield axios.delete(`/api/gestion-website/page/${id}`);
            yield put(actions.deletePageSuccess(id));
        }
        yield put(actions.alertNotificationStart(webSiteViewMsg['successDeletePage'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editPageFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemContentSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-website/content/${id}`);
        yield put(actions.getItemContentSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemContentFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* updateContentSaga(action) {
    try {
        const { id, formData, idDomain, history } = action.payload;

        const response = yield axios.put(`/api/gestion-website/content/${id}`, formData, headerContentTypeJson());

        yield put(actions.editContentSuccess(response.data));
        yield put(actions.alertNotificationStart(webSiteViewMsg['successEditContentPage'], 'success'));
        const urlListPageDomain = `${LINK_LIST_PAGE_DOMAIN_SHARE}/${idDomain}`;
        yield call([history, history.push], urlListPageDomain);
    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editContentFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemMetaMediaSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-website/meta-media/${id}`);
        yield put(actions.getItemMetaMediaSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemMetaMediaFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getListMetaMediaSaga(action) {
    try {
        const typeMeta = action.payload;
        let response = '';

        const paramQuery = {
            params: {
                metaType: typeMeta
            }
        }

        if (typeMeta === 'audio') {
            response = yield axios.get('/api/gestion-website/list-meta-media', paramQuery);
        } else if (typeMeta === 'video') {
            response = yield axios.get('/api/gestion-website/list-meta-media', paramQuery);
        } else {
            response = yield axios.get('/api/gestion-website/list-meta-media');
        }
        yield put(actions.getListMetaMediaSuccess(response.data));      
    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListMetaMediaFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postMetaMediaSaga(action) {
    try {
        const formData = action.payload;

        const response = yield axios.post('/api/gestion-website/meta-media', formData, headerContentTypeJson());

        yield put(actions.editMetaMediaSuccess(response.data));
        yield put(actions.alertNotificationStart(webSiteViewMsg['successCreateMeta'], 'success'));
        yield put(actions.getListMetaMediaStart(response.data.metaType));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editMetaMediaFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}



export function* deleteMetaMediaSaga(action) {
    try {
        const id = action.payload;

        yield axios.delete(`/api/gestion-website/meta-media/${id}`);

        yield put(actions.deleteMetaMediaSuccess(id));
        yield put(actions.alertNotificationStart(webSiteViewMsg['successDeleteMeta'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editMetaMediaFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}


export function* getItemMetaFileSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-website/meta-file/${id}`);
        yield put(actions.getItemMetaFileSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemMetaFileFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getListMetaFileSaga(action) {
    try {

        const typeMeta = action.payload;
        let response = '';

        const paramQuery = {
            params: {
                metaType: typeMeta
            }
        }
        if (typeMeta === 'image') {
            response = yield axios.get('/api/gestion-website/list-meta-file', paramQuery);
        } else if (typeMeta === 'application') {
            response = yield axios.get('/api/gestion-website/list-meta-file', paramQuery);
        } else {
            response = yield axios.get('/api/gestion-website/list-meta-file');
        } 
        yield put(actions.getListMetaFileSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListMetaFileFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postMetaFileSaga(action) {
    try {
        const formData = action.payload;

        const response = yield axios.post('/api/gestion-website/meta-file', formData, headerContentTypeJson());

        yield put(actions.editMetaFileSuccess(response.data));
        yield put(actions.alertNotificationStart(webSiteViewMsg['successCreateMeta'], 'success'));
        yield put(actions.getListMetaFileStart(response.data.metaType));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editMetaFileFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deleteMetaFileSaga(action) {
    try {
        const id = action.payload;

        yield axios.delete(`/api/gestion-website/meta-file/${id}`);

        yield put(actions.deleteMetaFileSuccess(id));
        yield put(actions.alertNotificationStart(webSiteViewMsg['successDeleteMeta'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editMetaFileFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}


export function* watchWebSite() {
    yield all([
        takeEvery(WebSiteTypes.GET_LIST_DOMAIN_START, getListDomainSaga),
        takeEvery(WebSiteTypes.GET_ITEM_DOMAIN_START, getItemDomainSaga),
        takeEvery(WebSiteTypes.DELETE_DOMAIN_START, deleteDomainSaga),
        takeEvery(WebSiteTypes.POST_DOMAIN_START, postDomainSaga),
        takeEvery(WebSiteTypes.UPDATE_DOMAIN_START, updateDomainSaga),
        takeEvery(WebSiteTypes.GET_LIST_PAGE_START, getListPageSaga),
        takeEvery(WebSiteTypes.GET_LIST_PAGE_SERMON_START, getListPageSermonSaga),
        takeEvery(WebSiteTypes.GET_ITEM_PAGE_START, getItemPageSaga),
        takeEvery(WebSiteTypes.POST_PAGE_START, postPageSaga),
        takeEvery(WebSiteTypes.UPDATE_PAGE_START, updatePageSaga),
        takeEvery(WebSiteTypes.DELETE_PAGE_START, deletePageSaga),
        takeEvery(WebSiteTypes.GET_ITEM_CONTENT_START, getItemContentSaga),
        takeEvery(WebSiteTypes.UPDATE_CONTENT_START, updateContentSaga),
        takeEvery(WebSiteTypes.GET_ITEM_META_MEDIA_START, getItemMetaMediaSaga),
        takeEvery(WebSiteTypes.GET_LIST_META_MEDIA_START, getListMetaMediaSaga),
        takeEvery(WebSiteTypes.POST_META_MEDIA_START, postMetaMediaSaga),
        takeEvery(WebSiteTypes.DELETE_META_MEDIA_START, deleteMetaMediaSaga),
        takeEvery(WebSiteTypes.GET_ITEM_META_FILE_START, getItemMetaFileSaga),
        takeEvery(WebSiteTypes.GET_LIST_META_FILE_START, getListMetaFileSaga),
        takeEvery(WebSiteTypes.POST_META_FILE_START, postMetaFileSaga),
        takeEvery(WebSiteTypes.DELETE_META_FILE_START, deleteMetaFileSaga),
        takeEvery(WebSiteTypes.GET_PAGES_DOMAIN_START, getPagesDomainSaga),
        takeEvery(WebSiteTypes.GET_PAGES_SERMON_DOMAIN_START, getPagesSermonDomainSaga),
        takeEvery(WebSiteTypes.SEARCH_PAGES_START, searchPagesSaga),
        takeEvery(WebSiteTypes.SEARCH_PAGES_SERMON_START, searchPagesSermonSaga)
    ]);
}