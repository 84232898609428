import { NotificationTypes } from '../../actions/actions.types';

const initialState = [];

export default function (state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case NotificationTypes.ALERT_NOTIFICATION_SUCCESS:
            return [...state, payload];
        case NotificationTypes.REMOVE_ALERT_NOTIFICATION:
            return state.filter(alertNotification => alertNotification.id !== payload);
        default:
            return state;
    }
}