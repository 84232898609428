export const webSiteViewMsg =  {
    successDeleteDomain: "Domaine supprimé avec succès",
    successCreateDomain: "Domaine crée avec succès",
    successUpdateDomain: "Domaine modifié avec succès",
    successDeletePage: "Page supprimée avec succès",
    successCreatePage: "Page créee avec succès",
    successUpdatePage: "Page modifiée avec succès",
    successEditContentPage: "Contenu de page ajouté ou modifié avec succès",
    successCreateMeta: "Fichier ou media crée avec succès",
    successUpdateMeta: "Fichier ou media modifié avec succès",
    successDeleteMeta: "Fichier ou media supprimé avec succès"
}

export const adminUserViewMsg =  {
    successCreateAdmin: 'Administrateur crée avec succès',
    successModifyAdminInfo : 'Informations administratives modifiéss avec succès',
    successModifyAccessApp : 'Droits à l\'application modifiés avec succès', 
    successModifyPass: 'Mot de passe modifié avec succès',
    successModifyProfilAdmin : 'Profil administratif modifié avec succès',
    successModifyProfil: 'Profil modifié avec succès',
    successDeleteProfil: 'Suppression du profil effectué avec succès'
}


export const scolarViewMsg =  {
    successDeleteInstitution: "Etablissement supprimé avec succès",
    successCreateInstitution: "Etablissement crée avec succès",
    successUpdateInstitution: "Etablissement modifié avec succès",
    successDeleteSpeciality: "Parcours supprimé avec succès",
    successCreateSpeciality: "Parcours crée avec succès",
    successUpdateSpeciality: "Parcours modifié avec succès",
    successDeleteGrade: "Niveau supprimé avec succès",
    successCreateGrade: "Niveau crée avec succès",
    successUpdateGrade: "Niveau modifié avec succès",
    successDeleteDegree: "Diplome supprimé avec succès",
    successCreateDegree: "Diplome crée avec succès",
    successUpdateDegree: "Diplome modifié avec succès",

    successCreateTeacher: "Enseignant crée avec succès",
    successDeleteTeacher: "Enseignant supprimé avec succès",
    successModifyTeacher : 'Enseignant modifié avec succès',
    successCreateStudent: "Etudiant crée avec succès",
    successDeleteStudent: "Etudiant supprimé avec succès",
    successModifyStudent : 'Etudiant modifié avec succès',

    successCreateUe: "UE (Module) crée avec succès",
    successDeleteUe: "UE (Module) supprimé avec succès",
    successUpdateUe : 'UE (Module) modifié avec succès',
    successDeleteMessage: "Message supprimé avec succès"
}

export const eLearningViewMsg = {
    successDeleteOnDomain: "Domaine supprimé avec succès",
    successCreateOnDomain: "Domaine crée avec succès",
    successUpdateOnDomain: "Domaine modifié avec succès",
    successDeleteSubDomain: "Sous domaine supprimé avec succès",
    successCreateSubDomain: "Sous domaine crée avec succès",
    successUpdateSubDomain: "Sous domaine modifié avec succès",
    successDeleteDocument: "Document supprimé avec succès",
    successCreateDocument: "Document crée avec succès",
    successUpdateDocument: "Document modifié avec succès",

    successDeleteProgram: "Programme supprimé avec succès",
    successCreateProgram: "Programme crée avec succès",
    successUpdateProgram: "Programme modifié avec succès",

    successDeleteCourse: "Cours supprimé avec succès",
    successCreateCourse: "Cours crée avec succès",
    successUpdateCourse: "Cours modifié avec succès",
}