import axios from '../axios/axios-instance';


export const setTokenAuthentication = token => {
    if (typeof token === 'undefined') {
        token = (localStorage.token) ? localStorage.getItem('token'): null;
    }

    if (token) {
        //axios.defaults.headers.common['Authorization'] = token;
       axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete  axios.defaults.headers.common['Authorization'];
    }
};


export const resetTokenAuthentication = () => {
    if (axios.defaults.headers.common['Authorization']) {
        delete  axios.defaults.headers.common['Authorization'];
    }
}

export const headerContentTypeJson = () => ({
    headers: { 
        "content-type": "application/json"
    }
})

export const headerContentTypeFormData = () => ({
    headers: { 'Content-Type': 'multipart/form-data'}
})
