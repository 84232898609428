import { put, takeEvery, delay  } from 'redux-saga/effects';
import { NotificationTypes } from '../../actions/actions.types';
import * as actions from '../../actions';
import { v4 as uuidv4 } from 'uuid';


export function* AlertNotificationSuccesSaga (action) {
    const idAlert = uuidv4();
    yield put(actions.alertNotificationSucces(action.payload,idAlert));
    yield delay(action.payload.expirationTime);
    yield put(actions.removeAlertNotification(idAlert));
}


export function* watchNotification() {
    yield takeEvery(NotificationTypes.ALERT_NOTIFICATION_START, AlertNotificationSuccesSaga);
}