import { createSelector } from 'reselect';

export const selectAuth = state => state.auth;

export const selectUserProfil = createSelector(
        [selectAuth],
        auth => auth.userProfil
);

export const selectIsAuthenticated = createSelector(
    [selectAuth],
    auth => auth.isAuthenticated
);

export const selectError = createSelector(
    [selectAuth],
    auth => auth.error
);

export const selectLoading = createSelector(
    [selectAuth],
    auth => auth.loading
);