import { ScolarTypes } from '../../actions/actions.types';

const initialState = {
    institution: {
        listInstitution: [],
        itemInstitution: null,
        loading: true,
        error: {},
    },
    speciality: {
        listSpeciality: [],
        itemSpeciality: null,
        loading: true,
        error: {},
    },
    grade: {
        listGrade: [],
        itemGrade: null,
        loading: true,
        error: {},
    },
    ue: {
        listUe: [],
        itemUe: null,
        loading: true,
        error: {},
    },
    degree: {
        listDegree: [],
        itemDegree: null,
        loading: true,
        error: {},
    },
    teacher: {
        listTeacher: [],
        itemTeacher: null,
        loading: true,
        error: {},
    },
    student: {
        listStudent: [],
        itemStudent: null,
        loading: true,
        error: {},
    },
    message: {
        listMessage: [],
        listMessageNotRead: [],
        itemMessage: null,
        loadingMessNotRead: true,
        loading: true,
        error: {},
    },
    statisticscol: {
        listStatistic: null,
        loading: true,
        error: {},
    },

}


export default function (state = initialState, action) {

    const { type, payload } = action;

    switch (type) {

        case ScolarTypes.EDIT_INSTITUTION_SUCCESS:
        case ScolarTypes.GET_ITEM_INSTITUTION_SUCCESS:
            return {
                ...state,
                institution: {
                    ...state.institution,
                    itemInstitution: payload,
                    loading: false,
                    error: {}
                }
            }
        case ScolarTypes.GET_LIST_INSTITUTION_SUCCESS:
            return {
                ...state,
                institution: {
                    ...state.institution,
                    listInstitution: payload,
                    loading: false,
                    error: {}
                }
            }
        case ScolarTypes.DELETE_INSTITUTION_SUCCESS:
            return {
                ...state,
                institution: {
                    ...state.institution,
                    listInstitution: {
                        ...state.institution.listInstitution,
                        "hydra:member": state.institution.listInstitution["hydra:member"].filter((itemSpeciality) => itemSpeciality.id !== payload),
                    },
                    loading: false,
                    error: {}
                }
            }
        case ScolarTypes.GET_ITEM_INSTITUTION_FAILURE:
        case ScolarTypes.EDIT_INSTITUTION_FAILURE:
        case ScolarTypes.GET_LIST_INSTITUTION_FAILURE:
            return {
                ...state,
                institution: {
                    ...state.institution,
                    error: payload,
                    loading: false
                }
            }
        case ScolarTypes.EDIT_SPECIALITY_SUCCESS:
        case ScolarTypes.GET_ITEM_SPECIALITY_SUCCESS:
            return {
                ...state,
                speciality: {
                    ...state.speciality,
                    itemSpeciality: payload,
                    loading: false
                }
            }
        case ScolarTypes.GET_LIST_SPECIALITY_SUCCESS:
            return {
                ...state,
                speciality: {
                    ...state.speciality,
                    listSpeciality: payload,
                    loading: false
                }
            }
        case ScolarTypes.DELETE_SPECIALITY_SUCCESS:
            return {
                ...state,
                speciality: {
                    ...state.speciality,
                    listSpeciality: {
                        ...state.speciality.listSpeciality,
                        "hydra:member": state.speciality.listSpeciality["hydra:member"].filter((itemSpeciality) => itemSpeciality.id !== payload),
                    },
                    loading: false,
                    error: {}
                }
            }
        case ScolarTypes.GET_ITEM_SPECIALITY_FAILURE:
        case ScolarTypes.EDIT_SPECIALITY_FAILURE:
        case ScolarTypes.GET_LIST_SPECIALITY_FAILURE:
            return {
                ...state,
                speciality: {
                    ...state.speciality,
                    error: payload,
                    loading: false
                }
            }
        case ScolarTypes.EDIT_GRADE_SUCCESS:
            return {
                ...state,
                grade: {
                    ...state.grade,
                    itemGrade: payload,
                    loading: false
                }
            }
        case ScolarTypes.GET_ITEM_GRADE_SUCCESS:
            return {
                ...state,
                grade: {
                    ...state.grade,
                    itemGrade: payload,
                    loading: false
                }
            }
        case ScolarTypes.GET_LIST_GRADE_SUCCESS:
            return {
                ...state,
                grade: {
                    ...state.grade,
                    listGrade: payload,
                    loading: false
                }
            }
        case ScolarTypes.DELETE_GRADE_SUCCESS:
            return {
                ...state,
                grade: {
                    ...state.grade,
                    listGrade: {
                        ...state.grade.listGrade,
                        "hydra:member": state.grade.listGrade["hydra:member"].filter((itemDegree) => itemDegree.id !== payload),
                    },
                    loading: false,
                    error: {}
                }
            }
        case ScolarTypes.GET_ITEM_GRADE_FAILURE:
        case ScolarTypes.EDIT_GRADE_FAILURE:
        case ScolarTypes.GET_LIST_GRADE_FAILURE:
            return {
                ...state,
                grade: {
                    ...state.grade,
                    error: payload,
                    loading: false
                }
            }
        case ScolarTypes.EDIT_DEGREE_SUCCESS:
            return {
                ...state,
                degree: {
                    ...state.degree,
                    itemDegree: payload,
                    loading: false
                }
            }
        case ScolarTypes.GET_ITEM_DEGREE_SUCCESS:
            return {
                ...state,
                degree: {
                    ...state.degree,
                    itemDegree: payload,
                    loading: false
                }
            }
        case ScolarTypes.GET_LIST_DEGREE_SUCCESS:
            return {
                ...state,
                degree: {
                    ...state.degree,
                    listDegree: payload,
                    loading: false
                }
            }
        case ScolarTypes.DELETE_DEGREE_SUCCESS:
            return {
                ...state,
                degree: {
                    ...state.degree,
                    listDegree: {
                        ...state.degree.listDegree,
                        "hydra:member": state.degree.listDegree["hydra:member"].filter((itemDegree) => itemDegree.id !== payload),
                    },
                    loading: false,
                    error: {}
                }
            }
        case ScolarTypes.EDIT_UE_SUCCESS:
            return {
                ...state,
                ue: {
                    ...state.ue,
                    itemUe: payload,
                    loading: false
                }
            }
        case ScolarTypes.GET_ITEM_UE_SUCCESS:
            return {
                ...state,
                ue: {
                    ...state.ue,
                    itemUe: payload,
                    loading: false
                }
            }
        case ScolarTypes.GET_LIST_UE_SUCCESS:
            return {
                ...state,
                ue: {
                    ...state.ue,
                    listUe: payload,
                    loading: false
                }
            }
        case ScolarTypes.DELETE_UE_SUCCESS:
            return {
                ...state,
                ue: {
                    ...state.ue,
                    listUe: {
                        ...state.ue.listUe,
                        "hydra:member": state.ue.listUe["hydra:member"].filter((itemUe) => itemUe.id !== payload),
                    },
                    loading: false,
                    error: {}
                }
            }
        case ScolarTypes.GET_ITEM_UE_FAILURE:
        case ScolarTypes.EDIT_UE_FAILURE:
        case ScolarTypes.GET_LIST_UE_FAILURE:
            return {
                ...state,
                ue: {
                    ...state.ue,
                    error: payload,
                    loading: false
                }
            }
        case ScolarTypes.GET_ITEM_DEGREE_FAILURE:
        case ScolarTypes.EDIT_DEGREE_FAILURE:
        case ScolarTypes.GET_LIST_DEGREE_FAILURE:
            return {
                ...state,
                degree: {
                    ...state.degree,
                    error: payload,
                    loading: false
                }
            }

        case ScolarTypes.EDIT_STUDENT_SUCCESS:
            return {
                ...state,
                student: {
                    ...state.student,
                    itemStudent: payload,
                    loading: false
                }
            }
        case ScolarTypes.GET_ITEM_STUDENT_SUCCESS:
            return {
                ...state,
                student: {
                    ...state.student,
                    itemStudent: payload,
                    loading: false
                }
            }
        case ScolarTypes.GET_LIST_STUDENT_SUCCESS:
            return {
                ...state,
                student: {
                    ...state.student,
                    listStudent: payload,
                    loading: false
                }
            }
        case ScolarTypes.DELETE_STUDENT_SUCCESS:
            return {
                ...state,
                student: {
                    ...state.student,
                    listStudent: state.student.listStudent.filter((itemStudent) => itemStudent.id !== payload),
                    loading: false,
                    error: {}
                }
            }
        case ScolarTypes.GET_ITEM_STUDENT_FAILURE:
        case ScolarTypes.EDIT_STUDENT_FAILURE:
        case ScolarTypes.GET_LIST_STUDENT_FAILURE:
            return {
                ...state,
                student: {
                    ...state.student,
                    error: payload,
                    loading: false
                }
            }
        case ScolarTypes.EDIT_TEACHER_SUCCESS:
            return {
                ...state,
                teacher: {
                    ...state.teacher,
                    itemTeacher: payload,
                    loading: false
                }
            }
        case ScolarTypes.GET_ITEM_TEACHER_SUCCESS:
            return {
                ...state,
                teacher: {
                    ...state.teacher,
                    itemTeacher: payload,
                    loading: false
                }
            }
        case ScolarTypes.GET_LIST_TEACHER_SUCCESS:
            return {
                ...state,
                teacher: {
                    ...state.teacher,
                    listTeacher: payload,
                    loading: false
                }
            }
        case ScolarTypes.DELETE_TEACHER_SUCCESS:
            return {
                ...state,
                teacher: {
                    ...state.teacher,
                    listTeacher: {
                        ...state.teacher.listTeacher,
                        "hydra:member": state.teacher.listTeacher["hydra:member"].filter((itemTeacher) => itemTeacher.id !== payload),
                    },
                    loading: false,
                    error: {}
                }
            }
        case ScolarTypes.GET_ITEM_TEACHER_FAILURE:
        case ScolarTypes.EDIT_TEACHER_FAILURE:
        case ScolarTypes.GET_LIST_TEACHER_FAILURE:
            return {
                ...state,
                teacher: {
                    ...state.teacher,
                    error: payload,
                    loading: false
                }
            }
        case ScolarTypes.EDIT_MESSAGE_SUCCESS:
        case ScolarTypes.GET_ITEM_MESSAGE_SUCCESS:
            return {
                ...state,
                message: {
                    ...state.message,
                    itemMessage: payload,
                    loading: false,
                    error: {}
                }
            }
        case ScolarTypes.GET_LIST_MESSAGE_SUCCESS:
            return {
                ...state,
                message: {
                    ...state.message,
                    listMessage: payload,
                    loading: false,
                    error: {}
                }
            }
        case ScolarTypes.GET_LIST_MESSAGE_NOT_READ_SUCCESS:
            return {
                ...state,
                message: {
                    ...state.message,
                    listMessageNotRead: payload,
                    loadingMessNotRead: false,
                    error: {}
                }
            }
        case ScolarTypes.DELETE_MESSAGE_SUCCESS:
            return {
                ...state,
                message: {
                    ...state.message,
                    listMessage: {
                        ...state.message.listMessage,
                        "hydra:member": state.message.listMessage["hydra:member"].filter((itemSpeciality) => itemSpeciality.id !== payload),
                    },
                    loading: false,
                    error: {}
                }
            }
        case ScolarTypes.GET_ITEM_MESSAGE_FAILURE:
        case ScolarTypes.EDIT_MESSAGE_FAILURE:
        case ScolarTypes.GET_LIST_MESSAGE_FAILURE:
            return {
                ...state,
                message: {
                    ...state.message,
                    error: payload,
                    loading: false
                }
            }
        case ScolarTypes.GET_LIST_STATISTIC_SCOL_SUCCESS:
            return {
                ...state,
                statisticscol: {
                    ...state.statisticscol,
                    listStatistic: payload,
                    loading: false,
                    error: {}
                }
            }
        case ScolarTypes.GET_LIST_STATISTIC_SCOL_FAILURE:
            return {
                ...state,
                statisticscol: {
                    ...state.statisticscol,
                    error: payload,
                    loading: false
                }
            }
        default:
            return state;
    }
}