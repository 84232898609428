

export const stringError = (oError, customMsg = null) => {

    let errorMsg = '';
    if (oError.response && oError.response.data && customMsg === null) {
        if (oError.response.data.violations) {
            errorMsg = oError.response.data.violations.reduce(
                (parsedErrors, violation) => {
                  parsedErrors[violation['propertyPath']] = violation['message'];
                  return parsedErrors;
                },
                {}
              );
              errorMsg = transObjectToString(errorMsg);
        }
        else if (oError.response.data['hydra:description']) {
            errorMsg = oError.response.data['hydra:description'];
        } else if (oError.response.data.message) {
            errorMsg = ( oError.response.data.code) ? customMesgByCode(oError.response.data.code): oError.response.data.message;
        } else if (oError.response.data['hydra:title']) {
            errorMsg = oError.response.data.message;
        } else {
            errorMsg = 'Une erreur est survenue';
        }
    } else if (customMsg !== null) {
        errorMsg = customMsg;
    } else {
        errorMsg = 'Une erreur est survenue';
    }
    return errorMsg;
}


function customMesgByCode(errorCode) {
    let errorCodeMsg = 'Une erreur est survenue'
    switch (errorCode) {
        case 401:
            errorCodeMsg = 'Identifiant ou mot de passe invalide';
            break;
        default:
            errorCodeMsg = 'Une erreur est survenue'
    }
    return errorCodeMsg;
}

function transObjectToString (obj) {
    let str = '';
    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            //str += p + ':' + obj[p] + '\n';
            str += obj[p] + '\n';
        }
    }
    str = (str === '') ? 'Une erreur est survenue': str;
    return str;
}