import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectIsAuthenticated, selectLoading, selectUserProfil } from "../../redux/selectors/auth/auth.selectors";

const PrivateRoute = ({
  component: Component,
   isAuthenticated, loading, userProfil ,moduleFailRedirect,
  ...rest
}) =>  {
  return (
    <Route
      {...rest}
      render={props => 
        (!isAuthenticated && !loading) ? (
          <Redirect to={moduleFailRedirect} />
        ) : (
          <Component userdata={userProfil} authenticated={isAuthenticated ? 'auth-success' : 'auth-fail'} loading={loading ? 'loading' : 'loaded'} {...props}  />
        )
      }
    />
  );
}



const mapStateToProps = createStructuredSelector({
  userProfil: selectUserProfil,
  isAuthenticated: selectIsAuthenticated,
  loading: selectLoading
});

export default React.memo(connect(mapStateToProps)(PrivateRoute));