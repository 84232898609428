import { ElearningTypes } from '../../actions/actions.types';

const initialState = {
    ondomain: {
        listOnDomain: [],
        itemOnDomain: null,
        loading: true,
        error: {},
    },
    subdomain: {
        listSubDomain: [],
        itemSubDomain: null,
        loading: true,
        error: {},
    },
    document: {
        listDocument: [],
        itemDocument: null,
        loading: true,
        error: {},
    },
    program: {
        listProgram: [],
        itemProgram: null,
        loading: true,
        error: {},
    },
    course: {
        listCourse: [],
        itemCourse: null,
        loading: true,
        error: {},
    },

}


export default function (state = initialState, action) {

    const { type, payload } = action;

    switch (type) {

        case ElearningTypes.EDIT_ONDOMAIN_SUCCESS:
        case ElearningTypes.GET_ITEM_ONDOMAIN_SUCCESS:
            return {
                ...state,
                ondomain: {
                    ...state.ondomain,
                    itemOnDomain: payload,
                    loading: false,
                    error: {}
                }
            }
        case ElearningTypes.GET_LIST_ONDOMAIN_SUCCESS:
            return {
                ...state,
                ondomain: {
                    ...state.ondomain,
                    listOnDomain: payload,
                    loading: false,
                    error: {}
                }
            }
        case ElearningTypes.DELETE_ONDOMAIN_SUCCESS:
            return {
                ...state,
                ondomain: {
                    ...state.ondomain,
                    listOnDomain: {
                        ...state.ondomain.listOnDomain,
                        "hydra:member": state.ondomain.listOnDomain["hydra:member"].filter((itemDomain) => itemDomain.id !== payload),
                    },
                    loading: false,
                    error: {}
                }
            }
        case ElearningTypes.GET_ITEM_ONDOMAIN_FAILURE:
        case ElearningTypes.EDIT_ONDOMAIN_FAILURE:
        case ElearningTypes.GET_LIST_ONDOMAIN_FAILURE:
            return {
                ...state,
                ondomain: {
                    ...state.ondomain,
                    error: payload,
                    loading: false
                }
            }
        case ElearningTypes.EDIT_SUB_DOMAIN_SUCCESS:
        case ElearningTypes.GET_ITEM_SUB_DOMAIN_SUCCESS:
            return {
                ...state,
                subdomain: {
                    ...state.subdomain,
                    itemSubDomain: payload,
                    loading: false
                }
            }
        case ElearningTypes.GET_LIST_SUB_DOMAIN_SUCCESS:
            return {
                ...state,
                subdomain: {
                    ...state.subdomain,
                    listSubDomain: payload,
                    loading: false
                }
            }
        case ElearningTypes.DELETE_SUB_DOMAIN_SUCCESS:
            return {
                ...state,
                subdomain: {
                    ...state.subdomain,
                    listSubDomain: {
                        ...state.subdomain.listSubDomain,
                        "hydra:member": state.subdomain.listSubDomain["hydra:member"].filter((itemSubDomain) => itemSubDomain.id !== payload),
                    },
                    loading: false,
                    error: {}
                }
            }
        case ElearningTypes.GET_ITEM_SUB_DOMAIN_FAILURE:
        case ElearningTypes.EDIT_SUB_DOMAIN_FAILURE:
        case ElearningTypes.GET_LIST_SUB_DOMAIN_FAILURE:
            return {
                ...state,
                subdomain: {
                    ...state.subdomain,
                    error: payload,
                    loading: false
                }
            }

        case ElearningTypes.EDIT_DOCUMENT_SUCCESS:
            return {
                ...state,
                document: {
                    ...state.document,
                    itemDocument: payload,
                    loading: false
                }
            }
        case ElearningTypes.GET_ITEM_DOCUMENT_SUCCESS:
            return {
                ...state,
                document: {
                    ...state.document,
                    itemDocument: payload,
                    loading: false
                }
            }
        case ElearningTypes.GET_LIST_DOCUMENT_SUCCESS:
            return {
                ...state,
                document: {
                    ...state.document,
                    listDocument: payload,
                    loading: false
                }
            }
        case ElearningTypes.DELETE_DOCUMENT_SUCCESS:
            return {
                ...state,
                document: {
                    ...state.document,
                    listDocument: {
                        ...state.document.listDocument,
                        "hydra:member": state.document.listDocument["hydra:member"].filter((itemDocument) => itemDocument.id !== payload),
                    },
                    loading: false,
                    error: {}
                }
            }
        case ElearningTypes.GET_ITEM_DOCUMENT_FAILURE:
        case ElearningTypes.EDIT_DOCUMENT_FAILURE:
        case ElearningTypes.GET_LIST_DOCUMENT_FAILURE:
            return {
                ...state,
                document: {
                    ...state.document,
                    error: payload,
                    loading: false
                }
            }
        case ElearningTypes.EDIT_PROGRAM_SUCCESS:
        case ElearningTypes.GET_ITEM_PROGRAM_SUCCESS:
            return {
                ...state,
                program: {
                    ...state.program,
                    itemProgram: payload,
                    loading: false,
                    error: {}
                }
            }
        case ElearningTypes.GET_LIST_PROGRAM_SUCCESS:
            return {
                ...state,
                program: {
                    ...state.program,
                    listProgram: payload,
                    loading: false,
                    error: {}
                }
            }
        case ElearningTypes.DELETE_PROGRAM_SUCCESS:
            return {
                ...state,
                program: {
                    ...state.program,
                    listProgram: state.program.listProgram.filter((itemProgram) => itemProgram.id !== payload),
                    loading: false,
                    error: {}
                }
            }
        case ElearningTypes.GET_ITEM_PROGRAM_FAILURE:
        case ElearningTypes.EDIT_PROGRAM_FAILURE:
        case ElearningTypes.GET_LIST_PROGRAM_FAILURE:
            return {
                ...state,
                program: {
                    ...state.program,
                    error: payload,
                    loading: false
                }
            }

        case ElearningTypes.EDIT_COURSE_SUCCESS:
            return {
                ...state,
                course: {
                    ...state.course,
                    itemCourse: payload,
                    loading: false
                }
            }
        case ElearningTypes.GET_ITEM_COURSE_SUCCESS:
            return {
                ...state,
                course: {
                    ...state.course,
                    itemCourse: payload,
                    loading: false
                }
            }
        case ElearningTypes.GET_LIST_COURSE_SUCCESS:
            return {
                ...state,
                course: {
                    ...state.course,
                    listCourse: payload,
                    loading: false
                }
            }
        case ElearningTypes.DELETE_COURSE_SUCCESS:
            return {
                ...state,
                course: {
                    ...state.course,
                    listCourse: state.course.listCourse.filter((itemCourse) => itemCourse.id !== payload),
                    loading: false,
                    error: {}
                }
            }
        case ElearningTypes.GET_ITEM_COURSE_FAILURE:
        case ElearningTypes.EDIT_COURSE_FAILURE:
        case ElearningTypes.GET_LIST_COURSE_FAILURE:
            return {
                ...state,
                course: {
                    ...state.course,
                    error: payload,
                    loading: false
                }
            }

        default:
            return state;
    }
}