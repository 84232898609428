import { takeEvery, put, all, call } from 'redux-saga/effects';
import { ScolarTypes } from '../../actions/actions.types';
import * as actions from '../../actions/index';
import axios from '../../../config/axios/axios-instance';
import { headerContentTypeJson } from '../../../config/auth/authentication';
import { stringError } from '../../../utils/error/apiErrorMsg';
import { scolarViewMsg, adminUserViewMsg } from '../../../config/view-msg/sagas.view-msg';
import { LINK_LIST_INSTITUTION, LINK_LIST_SPECIALITY_SHARE, LINK_LIST_DEGREE, LINK_LIST_UE_SHARE, LINK_LIST_GRADE_SHARE, LINK_LIST_STUDENT_SHARE, LINK_LIST_TEACHER } from '../../../routes';


export function* getListInstitutionSaga() {
    try {
        const response = yield axios.get('/api/gestion-scolarite/liste-etablissement');

        yield put(actions.getListInstitutionSuccess(response.data));

    } catch (error) {
    
        const errorMsg = stringError(error);
        yield put(actions.getListInstitutionFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemInstitutionSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-scolarite/etablissement/${id}`);
        yield put(actions.getItemInstitutionSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemInstitutionFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deleteInstitutionSaga(action) {
    try {
        const id = action.payload;
        yield axios.delete(`/api/gestion-scolarite/supprimer-etablissement/${id}`);
        yield put(actions.deleteInstitutionSuccess(id));
        yield put(actions.alertNotificationStart(scolarViewMsg['successDeleteInstitution'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editInstitutionFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postInstitutionSaga(action) {
    try {
        const { formData, history } = action.payload;

        const response = yield axios.post('/api/gestion-scolarite/creation-etablissement', formData, headerContentTypeJson());

        yield put(actions.editInstitutionSuccess(response.data));
        yield put(actions.alertNotificationStart(scolarViewMsg['successCreateInstitution'], 'success'));

        yield call([history, history.push], LINK_LIST_INSTITUTION);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editInstitutionFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* updateInstitutionSaga(action) {
    try {
        const { id, formData} = action.payload;
        const response = yield axios.put(`/api/gestion-scolarite/modifier-etablissement/${id}`, formData, headerContentTypeJson());
        yield put(actions.editInstitutionSuccess(response.data));
        yield put(actions.alertNotificationStart(scolarViewMsg['successUpdateInstitution'], 'success'));
        yield put(actions.getListInstitutionStart());
       // yield call([history, history.push], LINK_LIST_DOMAIN);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editInstitutionFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getListSpecialitySaga(action) {
    try {
        const paramQuery = action.payload;
        const response = yield axios.get('/api/gestion-scolarite/liste-parcours', paramQuery);

        yield put(actions.getListSpecialitySuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListSpecialityFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemSpecialitySaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-scolarite/parcours/${id}`);
        yield put(actions.getItemSpecialitySuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemSpecialityFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deleteSpecialitySaga(action) {
    try {
        const id = action.payload;
        yield axios.delete(`/api/gestion-scolarite/supprimer-parcours/${id}`);
        yield put(actions.deleteSpecialitySuccess(id));
        yield put(actions.alertNotificationStart(scolarViewMsg['successDeleteSpeciality'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editSpecialityFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postSpecialitySaga(action) {
    try {
        const { formData, history, idinstitution } = action.payload;

        const response = yield axios.post('/api/gestion-scolarite/creation-parcours', formData, headerContentTypeJson());

        yield put(actions.editSpecialitySuccess(response.data));
        yield put(actions.alertNotificationStart(scolarViewMsg['successCreateSpeciality'], 'success'));

        const urlListPage = `${LINK_LIST_SPECIALITY_SHARE}/${idinstitution}`;
        yield call([history, history.push], urlListPage);
        //yield call([history, history.push], LINK_LIST_DOMAIN);

    } catch (error) {
     
        const errorMsg = stringError(error);
        yield put(actions.editSpecialityFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* updateSpecialitySaga(action) {
    try {
        const { id, formData, paramQuery } = action.payload;
        const response = yield axios.put(`/api/gestion-scolarite/modifier-parcours/${id}`, formData, headerContentTypeJson());
        yield put(actions.editSpecialitySuccess(response.data));
        yield put(actions.alertNotificationStart(scolarViewMsg['successUpdateSpeciality'], 'success'));

        yield put(actions.getListSpecialityStart(paramQuery));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editSpecialityFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getListGradeSaga(action) {
    try {
        const paramQuery = action.payload;
        const response = yield axios.get('/api/gestion-scolarite/liste-niveau', paramQuery);

        yield put(actions.getListGradeSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListGradeFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemGradeSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-scolarite/niveau/${id}`);
        yield put(actions.getItemGradeSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemGradeFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deleteGradeSaga(action) {
    try {
        const id = action.payload;
        yield axios.delete(`/api/gestion-scolarite/supprimer-niveau/${id}`);
        yield put(actions.deleteGradeSuccess(id));
        yield put(actions.alertNotificationStart(scolarViewMsg['successDeleteGrade'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editGradeFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postGradeSaga(action) {
    try {
        const { formData, history, idspeciality } = action.payload;

        const response = yield axios.post('/api/gestion-scolarite/creation-niveau', formData, headerContentTypeJson());

        yield put(actions.editGradeSuccess(response.data));
        yield put(actions.alertNotificationStart(scolarViewMsg['successCreateGrade'], 'success'));

        const urlListPage = `${LINK_LIST_GRADE_SHARE}/${idspeciality}`;
        yield call([history, history.push], urlListPage);
        //yield call([history, history.push], LINK_LIST_DOMAIN);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editGradeFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* updateGradeSaga(action) {
    try {
        const { id, formData, paramQuery } = action.payload;
        const response = yield axios.put(`/api/gestion-scolarite/modifier-niveau/${id}`, formData, headerContentTypeJson());
        yield put(actions.editGradeSuccess(response.data));
        yield put(actions.alertNotificationStart(scolarViewMsg['successUpdateGrade'], 'success'));

        yield put(actions.getListGradeStart(paramQuery));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editGradeFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

/****************************************Ue***************************************************** */
export function* getListUeSaga(action) {
    try {
        const paramQuery = action.payload;
        let response = null;
       
        if (typeof paramQuery === 'string' && paramQuery === 'my-teacher-parcours-ue') {
            response = yield axios.get('/api/portal-digital/list-my-ue');
        } else if (typeof paramQuery === 'string' && paramQuery === 'my-student-parcours-ue') {
            response = yield axios.get('/api/portal-digital/list-student-ue-course');
        } else {
            response = yield axios.get('/api/gestion-scolarite/liste-ue', paramQuery);
        }

        yield put(actions.getListUeSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListUeFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemUeSaga(action) {
    try {
        const {id, param} = action.payload;
        let response = '';
        if (typeof param !== 'undefined' && param === 'student-ue') {
            response = yield axios.get(`/api/gestion-scolarite/detail-ue/${id}`);
        } else {
            response = yield axios.get(`/api/gestion-scolarite/ue/${id}`);
        }         
        yield put(actions.getItemUeSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemUeFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deleteUeSaga(action) {
    try {
        const id = action.payload;
        yield axios.delete(`/api/gestion-scolarite/supprimer-ue/${id}`);
        yield put(actions.deleteUeSuccess(id));
        yield put(actions.alertNotificationStart(scolarViewMsg['successDeleteUe'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editUeFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postUeSaga(action) {
    try {
        const { formData, history, idparent } = action.payload;

        const response = yield axios.post('/api/gestion-scolarite/creation-ue', formData, headerContentTypeJson());

        yield put(actions.editUeSuccess(response.data));
        yield put(actions.alertNotificationStart(scolarViewMsg['successCreateUe'], 'success'));

        const urlListPage = `${LINK_LIST_UE_SHARE}/${idparent}`;
        yield call([history, history.push], urlListPage);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editUeFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* updateUeSaga(action) {
    try {
        const { id, formData, param} = action.payload;
        const response = yield axios.put(`/api/gestion-scolarite/modifier-ue/${id}`, formData, headerContentTypeJson());
        yield put(actions.editUeSuccess(response.data));
        yield put(actions.alertNotificationStart(scolarViewMsg['successUpdateUe'], 'success'));

        yield put(actions.getListUeStart(param));
       // yield call([history, history.push], LINK_LIST_DOMAIN);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editUeFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

/********************************Degree***************************************************** */

export function* getListDegreeSaga() {
    try {
        const response = yield axios.get('/api/gestion-scolarite/liste-diplome');

        yield put(actions.getListDegreeSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListDegreeFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemDegreeSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-scolarite/diplome/${id}`);
        yield put(actions.getItemDegreeSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemDegreeFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deleteDegreeSaga(action) {
    try {
        const id = action.payload;
        yield axios.delete(`/api/gestion-scolarite/supprimer-diplome/${id}`);
        yield put(actions.deleteDegreeSuccess(id));
        yield put(actions.alertNotificationStart(scolarViewMsg['successDeleteDegree'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editDegreeFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postDegreeSaga(action) {
    try {
        const { formData, history } = action.payload;

        const response = yield axios.post('/api/gestion-scolarite/creation-diplome', formData, headerContentTypeJson());

        yield put(actions.editDegreeSuccess(response.data));
        yield put(actions.alertNotificationStart(scolarViewMsg['successCreateDegree'], 'success'));

        yield call([history, history.push], LINK_LIST_DEGREE);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editDegreeFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* updateDegreeSaga(action) {
    try {
        const { id, formData} = action.payload;
        const response = yield axios.put(`/api/gestion-scolarite/modifier-diplome/${id}`, formData, headerContentTypeJson());
        yield put(actions.editDegreeSuccess(response.data));
        yield put(actions.alertNotificationStart(scolarViewMsg['successUpdateDegree'], 'success'));

        yield put(actions.getListDegreeStart());
       // yield call([history, history.push], LINK_LIST_DOMAIN);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editDegreeFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

/******************Teacher ******************* */

export function* getListTeacherSaga() {
    try {
        const response = yield axios.get('/api/gestion-scolarite/list-teacher');

        yield put(actions.getListTeacherSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListTeacherFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemTeacherSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-scolarite/teacher/${id}`);
        yield put(actions.getItemTeacherSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemTeacherFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deleteTeacherSaga(action) {
    try {
        const id = action.payload;
        yield axios.delete(`/api/gestion-scolarite/delete-teacher/${id}`);
        yield put(actions.deleteTeacherSuccess(id));
        yield put(actions.alertNotificationStart(scolarViewMsg['successDeleteTeacher'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editTeacherFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postTeacherSaga(action) {
    try {
        const { formData, history } = action.payload;

        const response = yield axios.post('/api/gestion-scolarite/create-teacher', formData, headerContentTypeJson());

        yield put(actions.editTeacherSuccess(response.data));
        yield put(actions.alertNotificationStart(scolarViewMsg['successCreateTeacher'], 'success'));

        yield call([history, history.push], LINK_LIST_TEACHER);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editTeacherFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* updateTeacherSaga(action) {
    try {
        const { id, formData, typeEdit} = action.payload;
        let response = '';
        let msgSuccess = '';

        if (typeEdit === 'identity' || typeEdit === 'teacher') { 
            response = yield axios.put(`/api/gestion-scolarite/modify-teacher/${id}`, formData, headerContentTypeJson());
            msgSuccess = scolarViewMsg['successModifyTeacher']; 
        } else if (typeEdit === 'add-user-app_teach' || typeEdit === 'user-app-teach') {
            response = yield axios.put(`/api/gestion-scolarite/edit-connect-teacher/${id}`, formData, headerContentTypeJson());
             msgSuccess = adminUserViewMsg['successModifyAccessApp'];
        } else if (typeEdit === 'password') {
            response = yield axios.put(`/api/gestion-scolarite/edit-connect-pass-teacher/${id}`, formData, headerContentTypeJson());
             msgSuccess = adminUserViewMsg['successModifyPass']; 
        } else {
            response = yield axios.put(`/api/gestion-scolarite/modify-teacher/${id}`, formData, headerContentTypeJson());
            msgSuccess = scolarViewMsg['successModifyTeacher']; 
        }

        yield put(actions.editTeacherSuccess(response.data));
        yield put(actions.alertNotificationStart(msgSuccess, 'success'));

        yield put(actions.getListTeacherStart());

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editTeacherFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

/**********************Student**************************** */
export function* getListStudentSaga(action) {
    try {
        const paramQuery = action.payload;
        const response = yield axios.get(`/api/gestion-scolarite/list-student-grade/${paramQuery}`);

        yield put(actions.getListStudentSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListStudentFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemStudentSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-scolarite/student/${id}`);
        yield put(actions.getItemStudentSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemStudentFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deleteStudentSaga(action) {
    try {
        const id = action.payload;
        yield axios.delete(`/api/gestion-scolarite/delete-student/${id}`);
        yield put(actions.deleteStudentSuccess(id));
        yield put(actions.alertNotificationStart(scolarViewMsg['successDeleteStudent'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editStudentFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postStudentSaga(action) {
    try {
        const { formData, history, idgrade } = action.payload;

        const response = yield axios.post('/api/gestion-scolarite/create-student', formData, headerContentTypeJson());

        yield put(actions.editStudentSuccess(response.data));
        yield put(actions.alertNotificationStart(scolarViewMsg['successCreateStudent'], 'success'));

        const urlListPage = `${LINK_LIST_STUDENT_SHARE}/${idgrade}`;
        yield call([history, history.push], urlListPage);
        //yield call([history, history.push], LINK_LIST_DOMAIN);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editStudentFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* updateStudentSaga(action) {
    try {
        const { id, formData, typeEdit, param } = action.payload;
        
        let response = '';
        let msgSuccess = '';

        if (typeEdit === 'identity' || typeEdit === 'student') { 
            response = yield axios.put(`/api/gestion-scolarite/modify-student/${id}`, formData, headerContentTypeJson());
            msgSuccess = scolarViewMsg['successModifyStudent']; 
        } else if (typeEdit === 'add-user-app' || typeEdit === 'user-app') {
            response = yield axios.put(`/api/gestion-scolarite/edit-connect-student/${id}`, formData, headerContentTypeJson());
             msgSuccess = adminUserViewMsg['successModifyAccessApp'];
        } else if (typeEdit === 'password') {
            response = yield axios.put(`/api/gestion-scolarite/edit-connect-pass-student/${id}`, formData, headerContentTypeJson());
             msgSuccess = adminUserViewMsg['successModifyPass']; 
        } else {
            response = yield axios.put(`/api/gestion-scolarite/modify-student/${id}`, formData, headerContentTypeJson());
            msgSuccess = scolarViewMsg['successModifyStudent']; 
        }

        yield put(actions.editStudentSuccess(response.data));
        yield put(actions.alertNotificationStart(msgSuccess, 'success'));

        yield put(actions.getListStudentStart(param));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editStudentFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

/**************************************************************Message*************************************************************************** */
export function* getListMessageSaga() {
    try {
        const response = yield axios.get('/api/gestion-scolarite/list-message');

        yield put(actions.getListMessageSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListMessageFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getListMessageNotReadSaga() {
    try {
        const response = yield axios.get('/api/gestion-scolarite/liste-diplome');

        yield put(actions.getListMessageNotReadSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListMessageFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemMessageSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-scolarite/diplome/${id}`);
        yield put(actions.getItemMessageSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemMessageFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deleteMessageSaga(action) {
    try {
        const id = action.payload;
        yield axios.delete(`/api/gestion-scolarite/message/${id}`);
        yield put(actions.deleteMessageSuccess(id));
        yield put(actions.alertNotificationStart(scolarViewMsg['successDeleteMessage'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editMessageFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}



export function* updateMessageSaga(action) {
    try {
        const { id, formData} = action.payload;
        const response = yield axios.put(`/api/gestion-scolarite/modifier-diplome/${id}`, formData, headerContentTypeJson());
        yield put(actions.editMessageSuccess(response.data));
        yield put(actions.alertNotificationStart(scolarViewMsg['successUpdateMessage'], 'success'));

        yield put(actions.getListMessageStart());
       // yield call([history, history.push], LINK_LIST_DOMAIN);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editMessageFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

/*********************************************Statistic********************************** */

export function* getStatisticScolSaga() {
    try {
        const response = yield axios.get('/api/gestion-scolarite/statistic-scolar');
        yield put(actions.getListStatisticScolSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListStatisticScolFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}




export function* watchScolar() {
    yield all([
        takeEvery(ScolarTypes.GET_LIST_INSTITUTION_START, getListInstitutionSaga),
        takeEvery(ScolarTypes.GET_ITEM_INSTITUTION_START, getItemInstitutionSaga),
        takeEvery(ScolarTypes.DELETE_INSTITUTION_START, deleteInstitutionSaga),
        takeEvery(ScolarTypes.POST_INSTITUTION_START, postInstitutionSaga),
        takeEvery(ScolarTypes.UPDATE_INSTITUTION_START, updateInstitutionSaga),
        takeEvery(ScolarTypes.GET_LIST_SPECIALITY_START, getListSpecialitySaga),
        takeEvery(ScolarTypes.GET_ITEM_SPECIALITY_START, getItemSpecialitySaga),
        takeEvery(ScolarTypes.DELETE_SPECIALITY_START, deleteSpecialitySaga),
        takeEvery(ScolarTypes.POST_SPECIALITY_START, postSpecialitySaga),
        takeEvery(ScolarTypes.UPDATE_SPECIALITY_START, updateSpecialitySaga),
        takeEvery(ScolarTypes.GET_LIST_GRADE_START, getListGradeSaga),
        takeEvery(ScolarTypes.GET_ITEM_GRADE_START, getItemGradeSaga),
        takeEvery(ScolarTypes.DELETE_GRADE_START, deleteGradeSaga),
        takeEvery(ScolarTypes.POST_GRADE_START, postGradeSaga),
        takeEvery(ScolarTypes.UPDATE_GRADE_START, updateGradeSaga),
        takeEvery(ScolarTypes.GET_LIST_DEGREE_START, getListDegreeSaga),
        takeEvery(ScolarTypes.GET_ITEM_DEGREE_START, getItemDegreeSaga),
        takeEvery(ScolarTypes.DELETE_DEGREE_START, deleteDegreeSaga),
        takeEvery(ScolarTypes.POST_DEGREE_START, postDegreeSaga),
        takeEvery(ScolarTypes.UPDATE_DEGREE_START, updateDegreeSaga),

        takeEvery(ScolarTypes.GET_LIST_STUDENT_START, getListStudentSaga),
        takeEvery(ScolarTypes.GET_ITEM_STUDENT_START, getItemStudentSaga),
        takeEvery(ScolarTypes.DELETE_STUDENT_START, deleteStudentSaga),
        takeEvery(ScolarTypes.POST_STUDENT_START, postStudentSaga),
        takeEvery(ScolarTypes.UPDATE_STUDENT_START, updateStudentSaga),

        takeEvery(ScolarTypes.GET_LIST_TEACHER_START, getListTeacherSaga),
        takeEvery(ScolarTypes.GET_ITEM_TEACHER_START, getItemTeacherSaga),
        takeEvery(ScolarTypes.DELETE_TEACHER_START, deleteTeacherSaga),
        takeEvery(ScolarTypes.POST_TEACHER_START, postTeacherSaga),
        takeEvery(ScolarTypes.UPDATE_TEACHER_START, updateTeacherSaga),

        takeEvery(ScolarTypes.GET_LIST_UE_START, getListUeSaga),
        takeEvery(ScolarTypes.GET_ITEM_UE_START, getItemUeSaga),
        takeEvery(ScolarTypes.DELETE_UE_START, deleteUeSaga),
        takeEvery(ScolarTypes.POST_UE_START, postUeSaga),
        takeEvery(ScolarTypes.UPDATE_UE_START, updateUeSaga),

        takeEvery(ScolarTypes.GET_LIST_MESSAGE_START, getListMessageSaga),
        takeEvery(ScolarTypes.GET_LIST_MESSAGE_NOT_READ_START, getListMessageNotReadSaga),
        takeEvery(ScolarTypes.GET_ITEM_MESSAGE_START, getItemMessageSaga),
        takeEvery(ScolarTypes.DELETE_MESSAGE_START, deleteMessageSaga),
        takeEvery(ScolarTypes.UPDATE_MESSAGE_START, updateMessageSaga),
        takeEvery(ScolarTypes.GET_LIST_STATISTIC_SCOL_START, getStatisticScolSaga),
    ]);
}