import { FormAppTypes } from '../actions.types';

export const editFormDataStart = (dataEdit) => ({
            type: FormAppTypes.EDIT_FORM_DATA_START,
            payload: dataEdit
}); 

export const editFormDataSuccess = (dataEdit) => ({
    type: FormAppTypes.EDIT_FORM_DATA_SUCCCESS,
    payload: dataEdit
}); 

export const editFormDataBuilderStart = (dataEditBuilder) => ({
        type: FormAppTypes.EDIT_FORM_DATA_BUILDER_START,
        payload: dataEditBuilder
}); 

export const editFormDataBuilderSuccess = (dataEditBuilder) => ({
    type: FormAppTypes.EDIT_FORM_DATA_BUILDER_SUCCESS,
    payload: dataEditBuilder
}); 


export const rxformDeactivateFieldStart = (formName, fieldName) => ({
    type: FormAppTypes.RXFORM_DEACTIVATE_FIELD_START,
    payload: { formName, fieldName }
});

export const rxformSetFieldValueStart = (formName, fieldName, value) => ({
    type: FormAppTypes.RXFORM_SET_FIELD_VALUE_START,
    payload: { formName, fieldName, value }
})


export const editModalFormDataStart = (dataEdit) => ({
    type: FormAppTypes.EDIT_MODAL_FORM_DATA_START,
    payload: dataEdit
}); 

export const editModalFormDataSuccess = (dataEdit) => ({
type: FormAppTypes.EDIT_MODAL_FORM_DATA_SUCCCESS,
payload: dataEdit
}); 