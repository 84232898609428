import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';

import authReducer from './auth/auth.reducer';
import notificationReducer from './notification/notification.reducer';
import adminUserReducer from './admin-user/admin-user.reducer';
import formAppReducer from './form-app/form-app.reducer';
import webSiteReducer from './web-site/web-site.reducer';
import scolarReducer from './scolar/scolar.reducer';
import elearningReducer from './e-learning/e-learning.reducer';

export default combineReducers({
    auth: authReducer,
    form: reduxForm,
    notification: notificationReducer,
    adminUser: adminUserReducer,   
    formApp: formAppReducer,
    webSite: webSiteReducer,
    scolar: scolarReducer,
    eLearning: elearningReducer
});