import { takeEvery, put, all, call } from 'redux-saga/effects';
import { AuthActionTypes } from '../../actions/actions.types';
import * as actions from '../../actions/index';
import axios from '../../../config/axios/axios-instance';
import { resetTokenAuthentication, headerContentTypeJson, setTokenAuthentication } from '../../../config/auth/authentication';
import { stringError } from '../../../utils/error/apiErrorMsg';
import { BASE_URL } from '../../../config/axios/paramURLBack';


export function* signUpSaga(action) {

    const loginData = JSON.stringify(action.payload);
    try {
        //if already token is stored, reset it     
        resetTokenAuthentication();
      const urlPost = BASE_URL + "/api/login_check";
      const paramReq = {
        method: 'POST', // The method
        body: loginData,
      
        mode: "cors",
        redirect: "follow",
        headers: {
            // the content type header value is usually auto-set
            // depending on the request body
            "Content-Type": "application/json"
          }
      };
        const response = yield fetch(urlPost, paramReq);
        console.log(response)
        //const response = yield axios.post("/api/login_check", loginData, headerContentTypeJson());
        const extractResponse = yield response.json();
        //2*3600*1000 /*4 hrs in ms*/
        const currentDate = new Date().getTime();
        const expirationDate = yield new Date(currentDate + 1 * 3600 * 1000);
       
        console.log(extractResponse)
        const { token } = extractResponse;

        yield localStorage.setItem('token', token);
        yield localStorage.setItem('expirationDate', expirationDate);

        yield put(actions.signInSuccess(token));
        yield put(actions.checkUserSession());

      /*  yield put(actions.checkTimeoutSession(currentDate));   to implement after*/
    } catch (error) {
        console.log(error)
        const errorMsg = stringError(error);
        //const errorMsg = error.message;
        yield put(actions.signInFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error', 5000));
    }
}

export function* signOutSaga() {

        yield call([localStorage, 'removeItem'], 'token');
        yield call([localStorage, 'removeItem'], 'expirationDate');  //add later logic about expirationDate
        yield put(actions.signOutSuccess());
}

export function* getUserSessionSaga() {
    const token = yield localStorage.getItem('token');
    try {
        if (token) {
            //set header token
            setTokenAuthentication();
            const response = yield axios.get(`/api/my-profil/current-profil`);
            const { profilApp } = response.data;

            yield put(actions.getUserSession(profilApp));
        } else {
            yield put(actions.signOutStart());
        }
    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getUserSessionFail())
        yield put(actions.signOutStart());
        yield put(actions.alertNotificationStart(errorMsg, 'error', 5000));
    }
}


export function* watchAuth() {
    yield all([
        takeEvery(AuthActionTypes.SIGN_IN_START, signUpSaga),
        takeEvery(AuthActionTypes.CHECK_USER_SESSION, getUserSessionSaga),
        takeEvery(AuthActionTypes.SIGN_OUT_START, signOutSaga)
    ]);
}