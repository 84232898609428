import { WebSiteTypes } from '../../actions/actions.types';

const initialState = {
    domain: {
        listDomain: [],
        itemDomain: null,
        loading: true,
        error: {},
    },
    page: {
        listPage: [],
        listPageSermon: [],
        itemPage: null,
        loading: true,
        error: {},
        searchPages: [],
        searchPagesSermon: [],
    },
    content: {
        itemContent: null,
        loading: true,
        error: {},
    },
    meta: {
        itemMetaFile: null,
        listMetaFile: [],
        itemMetaMedia: null,
        listMetaMedia: [],
        loading: true,
        error: {}
    }
};

export default function (state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case WebSiteTypes.EDIT_DOMAIN_SUCCESS:
        case WebSiteTypes.GET_ITEM_DOMAIN_SUCCESS:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    itemDomain: payload,
                    loading: false
                }
            }
        case WebSiteTypes.GET_LIST_DOMAIN_SUCCESS:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    listDomain: payload,
                    loading: false
                }
            }
        case WebSiteTypes.DELETE_DOMAIN_SUCCESS:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    listDomain: state.domain.listDomain["hydra:member"].filter((itemDomain) => itemDomain.id !== payload),
                    loading: false
                }
            }
        case WebSiteTypes.GET_ITEM_DOMAIN_FAILURE:
        case WebSiteTypes.EDIT_DOMAIN_FAILURE:
        case WebSiteTypes.GET_LIST_DOMAIN_FAILURE:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    error: payload,
                    loading: false
                }
            }
        case WebSiteTypes.EDIT_PAGE_SUCCESS:
        case WebSiteTypes.GET_ITEM_PAGE_SUCCESS:
            return {
                ...state,
                page: {
                    ...state.page,
                    itemPage: payload,
                    loading: false
                }
            }
        case WebSiteTypes.GET_PAGES_DOMAIN_SUCCESS:
        case WebSiteTypes.GET_LIST_PAGE_SUCCESS:
            return {
                ...state,
                page: {
                    ...state.page,
                    listPage: payload,
                    loading: false
                }
            }
        case WebSiteTypes.SEARCH_PAGES_SUCCESS:
            return {
                ...state,
                page: {
                    ...state.page,
                    searchPages: payload,
                    loading: false
                }
            }
        case WebSiteTypes.GET_PAGES_SERMON_DOMAIN_SUCCESS:
        case WebSiteTypes.GET_LIST_PAGE_SERMON_SUCCESS:
            return {
                ...state,
                page: {
                    ...state.page,
                    listPageSermon: payload,
                    loading: false
                }
            }
        case WebSiteTypes.SEARCH_PAGES_SERMON_SUCCESS:
            return {
                ...state,
                page: {
                    ...state.page,
                    searchPagesSermon: payload,
                    loading: false
                }
            }
        case WebSiteTypes.DELETE_PAGE_SUCCESS:
        
            return {
                ...state,
                page: {
                    ...state.page,
                    listPage: {
                        ...state.page.listPage,
                        "hydra:member": state.page.listPage["hydra:member"].filter((itemPage) => itemPage.id !== payload),
                    },  
                    loading: false
                }
            }
        case WebSiteTypes.DELETE_PAGE_SERMON_SUCCESS:
            return {
                ...state,
                page: {
                    ...state.page,
                    listPageSermon: {
                        ...state.page.listPageSermon,
                        "hydra:member": state.page.listPageSermon["hydra:member"].filter((itemPage) => itemPage.id !== payload)
                    }, 
                    loading: false
                }
            }
        case WebSiteTypes.GET_PAGES_SERMON_DOMAIN_FAILURE:
        case WebSiteTypes.GET_PAGES_DOMAIN_FAILURE:
        case WebSiteTypes.EDIT_PAGE_FAILURE:
        case WebSiteTypes.GET_ITEM_PAGE_FAILURE:
        case WebSiteTypes.GET_LIST_PAGE_FAILURE:
        case WebSiteTypes.GET_LIST_PAGE_SERMON_FAILURE:
        case WebSiteTypes.SEARCH_PAGES_FAILURE:
        case WebSiteTypes.SEARCH_PAGES_SERMON_FAILURE:
            return {
                ...state,
                page: {
                    ...state.page,
                    error: payload,
                    loading: false
                }
            }
        case WebSiteTypes.GET_ITEM_CONTENT_SUCCESS:
        case WebSiteTypes.EDIT_CONTENT_SUCCESS:
            return {
                ...state,
                content: {
                    ...state.content,
                    itemContent: payload,
                    loading: false
                }
            }
        case WebSiteTypes.GET_ITEM_CONTENT_FAILURE:
        case WebSiteTypes.EDIT_CONTENT_FAILURE:
            return {
                ...state,
                content: {
                    ...state.content,
                    error: payload,
                    loading: false
                }
            }
        case WebSiteTypes.GET_ITEM_META_FILE_SUCCESS:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    itemMetaFile: payload,
                    loading: false
                }
            }
        case WebSiteTypes.EDIT_META_FILE_SUCCESS:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    itemMetaFile: payload,
                    loading: false
                }
            }
        case WebSiteTypes.GET_LIST_META_FILE_SUCCESS:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    listMetaFile: payload,
                    loading: false
                }
            }
        case WebSiteTypes.DELETE_META_FILE_SUCCESS:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    listMetaFile: state.meta.listMetaFile["hydra:member"].filter((itemMetaFile) => itemMetaFile.id !== payload),
                    loading: false
                }
            }
        case WebSiteTypes.GET_ITEM_META_MEDIA_SUCCESS:
        case WebSiteTypes.EDIT_META_MEDIA_SUCCESS:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    itemMetaMedia: payload,
                    loading: false
                }
            }
        case WebSiteTypes.GET_LIST_META_MEDIA_SUCCESS:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    listMetaMedia: payload,
                    loading: false
                }
            }
        case WebSiteTypes.DELETE_META_MEDIA_SUCCESS:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    listMetaMedia: state.meta.listMetaMedia["hydra:member"].filter((itemMetaMedia) => itemMetaMedia.id !== payload),
                    loading: false
                }
            }
        case WebSiteTypes.GET_LIST_META_MEDIA_FAILURE:
        case WebSiteTypes.EDIT_META_MEDIA_FAILURE:
        case WebSiteTypes.GET_ITEM_META_MEDIA_FAILURE:
        case WebSiteTypes.GET_LIST_META_FILE_FAILURE:
        case WebSiteTypes.EDIT_META_FILE_FAILURE:
        case WebSiteTypes.GET_ITEM_META_FILE_FAILURE:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    error: payload,
                    loading: false
                }
            }
        default:
            return state;
    }

}