export const AuthActionTypes = {
    SIGN_IN_START: 'SIGN_IN_START',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
    SIGN_OUT_START: 'SIGN_OUT_START',
    SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
    SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
    CHECK_USER_SESSION: 'CHECK_USER_SESSION',
    GET_USER_SESSION: 'GET_USER_SESSION',
    GET_USER_SESSION_FAIL: 'GET_USER_SESSION_FAIL',
    CHECK_TIMEOUT_SESSION: 'CHECK_TIMEOUT_SESSION'
};

export const NotificationTypes = {
    ALERT_NOTIFICATION_START: 'ALERT_NOTIFICATION_START',
    ALERT_NOTIFICATION_SUCCESS: 'ALERT_NOTIFICATION_SUCCESS',
    REMOVE_ALERT_NOTIFICATION: 'REMOVE_ALERT_NOTIFICATION'
};

export const AdminUserTypes = {
    GET_LIST_ADMIN_START: 'GET_LIST_ADMIN_START',
    GET_LIST_ADMIN_SUCCESS: 'GET_LIST_ADMIN_SUCCESS',
    GET_LIST_ADMIN_FAILURE: 'GET_LIST_ADMIN_FAILURE',
    GET_ITEM_ADMIN_START: 'GET_ITEM_ADMIN_START',
    GET_ITEM_ADMIN_SUCCESS: 'GET_ITEM_ADMIN_SUCCESS',
    GET_ITEM_ADMIN_FAILURE: 'GET_ITEM_ADMIN_FAILURE',
    POST_ADMIN_START: 'POST_ADMIN_START',
    UPDATE_ADMIN_START: 'UPDATE_ADMIN_START',
    DELETE_ADMIN_START: 'DELETE_ADMIN_START',
    DELETE_ADMIN_SUCCESS: 'DELETE_ADMIN_SUCCESS',
    EDIT_SUCCESS: 'EDIT_SUCCESS',
    EDIT_FAILURE: 'EDIT_FAILURE'
}

export const FormAppTypes = {
    EDIT_FORM_DATA_START: 'EDIT_FORM_DATA_START',
    EDIT_FORM_DATA_SUCCCESS: 'EDIT_FORM_DATA_SUCCCESS',
    EDIT_FORM_DATA_BUILDER_START: 'EDIT_FORM_DATA_BUILDER_START',
    EDIT_FORM_DATA_BUILDER_SUCCESS: 'EDIT_FORM_DATA_BUILDER_SUCCESS',
    RXFORM_DEACTIVATE_FIELD_START: 'RXFORM_DEACTIVATE_FIELD_START',
    RXFORM_SET_FIELD_VALUE_START: 'RXFORM_SET_FIELD_VALUE_START',
    EDIT_MODAL_FORM_DATA_START: 'EDIT_MODAL_FORM_DATA_START',  
    EDIT_MODAL_FORM_DATA_SUCCCESS: 'EDIT_MODAL_FORM_DATA_SUCCCESS',
}

export const WebSiteTypes = {
    GET_LIST_DOMAIN_START: 'GET_LIST_DOMAIN_START',
    GET_LIST_DOMAIN_SUCCESS: 'GET_LIST_DOMAIN_SUCCESS',
    GET_LIST_DOMAIN_FAILURE: 'GET_LIST_DOMAIN_FAILURE',
    GET_ITEM_DOMAIN_START: 'GET_ITEM_DOMAIN_START',
    GET_ITEM_DOMAIN_SUCCESS: 'GET_ITEM_DOMAIN_SUCCESS',
    GET_ITEM_DOMAIN_FAILURE: 'GET_ITEM_DOMAIN_FAILURE',
    DELETE_DOMAIN_START: 'DELETE_DOMAIN_START',
    DELETE_DOMAIN_SUCCESS: 'DELETE_DOMAIN_SUCCESS',
    POST_DOMAIN_START: 'POST_DOMAIN_START',
    UPDATE_DOMAIN_START: 'UPDATE_DOMAIN_START',
    EDIT_DOMAIN_SUCCESS: 'EDIT_DOMAIN_SUCCESS',
    EDIT_DOMAIN_FAILURE: 'EDIT_DOMAIN_FAILURE',
    GET_LIST_PAGE_START: 'GET_LIST_PAGE_START',
    GET_LIST_PAGE_SUCCESS: 'GET_LIST_PAGE_SUCCESS',
    GET_LIST_PAGE_SERMON_START: 'GET_LIST_PAGE_SERMON_START',
    GET_LIST_PAGE_SERMON_SUCCESS: 'GET_LIST_PAGE_SERMON_SUCCESS',
    GET_LIST_PAGE_SERMON_FAILURE: 'GET_LIST_PAGE_SERMON_FAILURE',
    GET_LIST_PAGE_FAILURE: 'GET_LIST_PAGE_FAILURE',
    GET_ITEM_PAGE_START: 'GET_ITEM_PAGE_START',
    GET_ITEM_PAGE_SUCCESS: 'GET_ITEM_PAGE_SUCCESS',
    GET_ITEM_PAGE_FAILURE: 'GET_ITEM_PAGE_FAILURE',
    POST_PAGE_START: 'POST_PAGE_START',
    UPDATE_PAGE_START: 'UPDATE_PAGE_START',
    EDIT_PAGE_SUCCESS: 'EDIT_PAGE_SUCCESS',
    EDIT_PAGE_FAILURE: 'EDIT_PAGE_FAILURE',
    DELETE_PAGE_START: 'DELETE_PAGE_START',
    DELETE_PAGE_SUCCESS: 'DELETE_PAGE_SUCCESS',
    DELETE_PAGE_SERMON_SUCCESS: 'DELETE_PAGE_SERMON_SUCCESS',
    GET_ITEM_CONTENT_START: 'GET_ITEM_CONTENT_START',
    GET_ITEM_CONTENT_SUCCESS: 'GET_ITEM_CONTENT_SUCCESS',
    GET_ITEM_CONTENT_FAILURE: 'GET_ITEM_CONTENT_FAILURE',
    UPDATE_CONTENT_START: 'UPDATE_CONTENT_START',
    EDIT_CONTENT_SUCCESS: 'EDIT_CONTENT_SUCCESS',
    EDIT_CONTENT_FAILURE: 'EDIT_CONTENT_FAILURE',
    POST_META_MEDIA_START: 'POST_META_MEDIA_START',
    POST_META_FILE_START: 'POST_META_FILE_START',
    EDIT_META_MEDIA_SUCCESS: 'EDIT_META_MEDIA_SUCCESS',
    EDIT_META_FILE_SUCCESS: 'EDIT_META_FILE_SUCCESS',
    EDIT_META_MEDIA_FAILURE: 'EDIT_META_MEDIA_FAILURE',
    EDIT_META_FILE_FAILURE: 'EDIT_META_FILE_FAILURE',
    GET_ITEM_META_MEDIA_START: 'GET_ITEM_META_MEDIA_START',
    GET_ITEM_META_MEDIA_SUCCESS: 'GET_ITEM_META_MEDIA_SUCCESS',
    GET_ITEM_META_MEDIA_FAILURE: 'GET_ITEM_META_MEDIA_FAILURE',
    GET_LIST_META_MEDIA_START: 'GET_LIST_META_MEDIA_START',
    GET_LIST_META_MEDIA_SUCCESS: 'GET_LIST_META_MEDIA_SUCCESS',
    GET_LIST_META_MEDIA_FAILURE: 'GET_LIST_META_MEDIA_FAILURE',
    GET_ITEM_META_FILE_START: 'GET_ITEM_META_FILE_START',
    GET_ITEM_META_FILE_SUCCESS: 'GET_ITEM_META_FILE_SUCCESS',
    GET_ITEM_META_FILE_FAILURE: 'GET_ITEM_META_FILE_FAILURE',
    GET_LIST_META_FILE_START: 'GET_LIST_META_FILE_START',
    GET_LIST_META_FILE_SUCCESS: 'GET_LIST_META_FILE_SUCCESS',
    GET_LIST_META_FILE_FAILURE: 'GET_LIST_META_FILE_FAILURE',
    DELETE_META_FILE_START: 'DELETE_META_FILE_START',
    DELETE_META_FILE_SUCCESS: 'DELETE_META_FILE_SUCCESS',
    DELETE_META_MEDIA_START: 'DELETE_META_MEDIA_START',
    DELETE_META_MEDIA_SUCCESS: 'DELETE_META_MEDIA_SUCCESS',

    GET_PAGES_SERMON_DOMAIN_START: 'GET_PAGES_SERMON_DOMAIN_START',
    GET_PAGES_SERMON_DOMAIN_SUCCESS: 'GET_PAGES_SERMON_DOMAIN_SUCCESS',
    GET_PAGES_SERMON_DOMAIN_FAILURE: 'GET_PAGES_SERMON_DOMAIN_FAILURE',

    GET_PAGES_DOMAIN_START: 'GET_PAGES_DOMAIN_START',
    GET_PAGES_DOMAIN_SUCCESS: 'GET_PAGES_DOMAIN_SUCCESS',
    GET_PAGES_DOMAIN_FAILURE: 'GET_PAGES_DOMAIN_FAILURE',

    SEARCH_PAGES_SERMON_START: 'SEARCH_PAGES_SERMON_START',
    SEARCH_PAGES_SERMON_SUCCESS: 'SEARCH_PAGES_SERMON_SUCCESS',
    SEARCH_PAGES_SERMON_FAILURE: 'SEARCH_PAGES_SERMON_FAILURE',

    SEARCH_PAGES_START: 'SEARCH_PAGES_START',
    SEARCH_PAGES_SUCCESS: 'SEARCH_PAGES_SUCCESS',
    SEARCH_PAGES_FAILURE: 'SEARCH_PAGES_FAILURE',
    
}

export const ScolarTypes = {
    GET_LIST_INSTITUTION_START: 'GET_LIST_INSTITUTION_START',
    GET_LIST_INSTITUTION_SUCCESS: 'GET_LIST_INSTITUTION_SUCCESS',
    GET_LIST_INSTITUTION_FAILURE: 'GET_LIST_INSTITUTION_FAILURE',
    GET_ITEM_INSTITUTION_START: 'GET_ITEM_INSTITUTION_START',
    GET_ITEM_INSTITUTION_SUCCESS: 'GET_ITEM_INSTITUTION_SUCCESS',
    GET_ITEM_INSTITUTION_FAILURE: 'GET_ITEM_INSTITUTION_FAILURE',
    DELETE_INSTITUTION_START: 'DELETE_INSTITUTION_START',
    DELETE_INSTITUTION_SUCCESS: 'DELETE_INSTITUTION_SUCCESS',
    POST_INSTITUTION_START: 'POST_INSTITUTION_START',
    UPDATE_INSTITUTION_START: 'UPDATE_INSTITUTION_START',
    EDIT_INSTITUTION_SUCCESS: 'EDIT_INSTITUTION_SUCCESS',
    EDIT_INSTITUTION_FAILURE: 'EDIT_INSTITUTION_FAILURE',
    GET_LIST_SPECIALITY_START: 'GET_LIST_SPECIALITY_START',
    GET_LIST_SPECIALITY_SUCCESS: 'GET_LIST_SPECIALITY_SUCCESS',
    GET_LIST_SPECIALITY_FAILURE: 'GET_LIST_SPECIALITY_FAILURE',
    GET_ITEM_SPECIALITY_START: 'GET_ITEM_SPECIALITY_START',
    GET_ITEM_SPECIALITY_SUCCESS: 'GET_ITEM_SPECIALITY_SUCCESS',
    GET_ITEM_SPECIALITY_FAILURE: 'GET_ITEM_SPECIALITY_FAILURE',
    DELETE_SPECIALITY_START: 'DELETE_SPECIALITY_START',
    DELETE_SPECIALITY_SUCCESS: 'DELETE_SPECIALITY_SUCCESS',
    POST_SPECIALITY_START: 'POST_SPECIALITY_START',
    UPDATE_SPECIALITY_START: 'UPDATE_SPECIALITY_START',
    EDIT_SPECIALITY_SUCCESS: 'EDIT_SPECIALITY_SUCCESS',
    EDIT_SPECIALITY_FAILURE: 'EDIT_SPECIALITY_FAILURE',
    GET_LIST_GRADE_START: 'GET_LIST_GRADE_START',
    GET_LIST_GRADE_SUCCESS: 'GET_LIST_GRADE_SUCCESS',
    GET_LIST_GRADE_FAILURE: 'GET_LIST_GRADE_FAILURE',
    GET_ITEM_GRADE_START: 'GET_ITEM_GRADE_START',
    GET_ITEM_GRADE_SUCCESS: 'GET_ITEM_GRADE_SUCCESS',
    GET_ITEM_GRADE_FAILURE: 'GET_ITEM_GRADE_FAILURE',
    DELETE_GRADE_START: 'DELETE_GRADE_START',
    DELETE_GRADE_SUCCESS: 'DELETE_GRADE_SUCCESS',
    POST_GRADE_START: 'POST_GRADE_START',
    UPDATE_GRADE_START: 'UPDATE_GRADE_START',
    EDIT_GRADE_SUCCESS: 'EDIT_GRADE_SUCCESS',
    EDIT_GRADE_FAILURE: 'EDIT_GRADE_FAILURE',
   
    GET_LIST_DEGREE_START: 'GET_LIST_DEGREE_START',
    GET_LIST_DEGREE_SUCCESS: 'GET_LIST_DEGREE_SUCCESS',
    GET_LIST_DEGREE_FAILURE: 'GET_LIST_DEGREE_FAILURE',
    GET_ITEM_DEGREE_START: 'GET_ITEM_DEGREE_START',
    GET_ITEM_DEGREE_SUCCESS: 'GET_ITEM_DEGREE_SUCCESS',
    GET_ITEM_DEGREE_FAILURE: 'GET_ITEM_DEGREE_FAILURE',
    DELETE_DEGREE_START: 'DELETE_DEGREE_START',
    DELETE_DEGREE_SUCCESS: 'DELETE_DEGREE_SUCCESS',
    POST_DEGREE_START: 'POST_DEGREE_START',
    UPDATE_DEGREE_START: 'UPDATE_DEGREE_START',
    EDIT_DEGREE_SUCCESS: 'EDIT_DEGREE_SUCCESS',
    EDIT_DEGREE_FAILURE: 'EDIT_DEGREE_FAILURE',

    GET_LIST_UE_START: 'GET_LIST_UE_START',
    GET_LIST_UE_SUCCESS: 'GET_LIST_UE_SUCCESS',
    GET_LIST_UE_FAILURE: 'GET_LIST_UE_FAILURE',
    GET_ITEM_UE_START: 'GET_ITEM_UE_START',
    GET_ITEM_UE_SUCCESS: 'GET_ITEM_UE_SUCCESS',
    GET_ITEM_UE_FAILURE: 'GET_ITEM_UE_FAILURE',
    DELETE_UE_START: 'DELETE_UE_START',
    DELETE_UE_SUCCESS: 'DELETE_UE_SUCCESS',
    POST_UE_START: 'POST_UE_START',
    UPDATE_UE_START: 'UPDATE_UE_START',
    EDIT_UE_SUCCESS: 'EDIT_UE_SUCCESS',
    EDIT_UE_FAILURE: 'EDIT_UE_FAILURE',

    GET_LIST_STUDENT_START: 'GET_LIST_STUDENT_START',
    GET_LIST_STUDENT_SUCCESS: 'GET_LIST_STUDENT_SUCCESS',
    GET_LIST_STUDENT_FAILURE: 'GET_LIST_STUDENT_FAILURE',
    GET_ITEM_STUDENT_START: 'GET_ITEM_STUDENT_START',
    GET_ITEM_STUDENT_SUCCESS: 'GET_ITEM_STUDENT_SUCCESS',
    GET_ITEM_STUDENT_FAILURE: 'GET_ITEM_STUDENT_FAILURE',
    POST_STUDENT_START: 'POST_STUDENT_START',
    UPDATE_STUDENT_START: 'UPDATE_STUDENT_START',
    DELETE_STUDENT_START: 'DELETE_STUDENT_START',
    DELETE_STUDENT_SUCCESS: 'DELETE_STUDENT_SUCCESS',
    EDIT_STUDENT_SUCCESS: 'EDIT_STUDENT_SUCCESS',
    EDIT_STUDENT_FAILURE: 'EDIT_STUDENT_FAILURE',
    
    GET_LIST_TEACHER_START: 'GET_LIST_TEACHER_START',
    GET_LIST_TEACHER_SUCCESS: 'GET_LIST_TEACHER_SUCCESS',
    GET_LIST_TEACHER_FAILURE: 'GET_LIST_TEACHER_FAILURE',
    GET_ITEM_TEACHER_START: 'GET_ITEM_TEACHER_START',
    GET_ITEM_TEACHER_SUCCESS: 'GET_ITEM_TEACHER_SUCCESS',
    GET_ITEM_TEACHER_FAILURE: 'GET_ITEM_TEACHER_FAILURE',
    POST_TEACHER_START: 'POST_TEACHER_START',
    UPDATE_TEACHER_START: 'UPDATE_TEACHER_START',
    DELETE_TEACHER_START: 'DELETE_TEACHER_START',
    DELETE_TEACHER_SUCCESS: 'DELETE_TEACHER_SUCCESS',
    EDIT_TEACHER_SUCCESS: 'EDIT_TEACHER_SUCCESS',
    EDIT_TEACHER_FAILURE: 'EDIT_TEACHER_FAILURE' ,

    GET_LIST_MESSAGE_START: 'GET_LIST_MESSAGE_START',
    GET_LIST_MESSAGE_SUCCESS: 'GET_LIST_MESSAGE_SUCCESS',
    GET_LIST_MESSAGE_NOT_READ_START: 'GET_LIST_MESSAGE_NOT_READ_START',
    GET_LIST_MESSAGE_NOT_READ_SUCCESS: 'GET_LIST_MESSAGE_NOT_READ_SUCCESS',
    GET_LIST_MESSAGE_FAILURE: 'GET_LIST_MESSAGE_FAILURE',
    GET_ITEM_MESSAGE_START: 'GET_ITEM_MESSAGE_START',
    GET_ITEM_MESSAGE_SUCCESS: 'GET_ITEM_MESSAGE_SUCCESS',
    GET_ITEM_MESSAGE_FAILURE: 'GET_ITEM_MESSAGE_FAILURE',
    DELETE_MESSAGE_START: 'DELETE_MESSAGE_START',
    DELETE_MESSAGE_SUCCESS: 'DELETE_MESSAGE_SUCCESS',
    UPDATE_MESSAGE_START: 'UPDATE_MESSAGE_START',
    EDIT_MESSAGE_SUCCESS: 'EDIT_MESSAGE_SUCCESS',
    EDIT_MESSAGE_FAILURE: 'EDIT_MESSAGE_FAILURE',

    GET_LIST_STATISTIC_SCOL_START: 'GET_LIST_STATISTIC_SCOL_START',
    GET_LIST_STATISTIC_SCOL_SUCCESS: 'GET_LIST_STATISTIC_SCOL_SUCCESS',
    GET_LIST_STATISTIC_SCOL_FAILURE: 'GET_LIST_STATISTIC_SCOL_FAILURE'
}

export const ElearningTypes = {
    GET_LIST_ONDOMAIN_START: 'GET_LIST_ONDOMAIN_START',
    GET_LIST_ONDOMAIN_SUCCESS: 'GET_LIST_ONDOMAIN_SUCCESS',
    GET_LIST_ONDOMAIN_FAILURE: 'GET_LIST_ONDOMAIN_FAILURE',
    GET_ITEM_ONDOMAIN_START: 'GET_ITEM_ONDOMAIN_START',
    GET_ITEM_ONDOMAIN_SUCCESS: 'GET_ITEM_ONDOMAIN_SUCCESS',
    GET_ITEM_ONDOMAIN_FAILURE: 'GET_ITEM_ONDOMAIN_FAILURE',
    POST_ONDOMAIN_START: 'POST_ONDOMAIN_START',
    UPDATE_ONDOMAIN_START: 'UPDATE_ONDOMAIN_START',
    DELETE_ONDOMAIN_START: 'DELETE_ONDOMAIN_START',
    DELETE_ONDOMAIN_SUCCESS: 'DELETE_ONDOMAIN_SUCCESS',
    EDIT_ONDOMAIN_SUCCESS: 'EDIT_ONDOMAIN_SUCCESS',
    EDIT_ONDOMAIN_FAILURE: 'EDIT_ONDOMAIN_FAILURE',

    GET_LIST_SUB_DOMAIN_START: 'GET_LIST_SUB_DOMAIN_START',
    GET_LIST_SUB_DOMAIN_SUCCESS: 'GET_LIST_SUB_DOMAIN_SUCCESS',
    GET_LIST_SUB_DOMAIN_FAILURE: 'GET_LIST_SUB_DOMAIN_FAILURE',
    GET_ITEM_SUB_DOMAIN_START: 'GET_ITEM_SUB_DOMAIN_START',
    GET_ITEM_SUB_DOMAIN_SUCCESS: 'GET_ITEM_SUB_DOMAIN_SUCCESS',
    GET_ITEM_SUB_DOMAIN_FAILURE: 'GET_ITEM_SUB_DOMAIN_FAILURE',
    POST_SUB_DOMAIN_START: 'POST_SUB_DOMAIN_START',
    UPDATE_SUB_DOMAIN_START: 'UPDATE_SUB_DOMAIN_START',
    DELETE_SUB_DOMAIN_START: 'DELETE_SUB_DOMAIN_START',
    DELETE_SUB_DOMAIN_SUCCESS: 'DELETE_SUB_DOMAIN_SUCCESS',
    EDIT_SUB_DOMAIN_SUCCESS: 'EDIT_SUB_DOMAIN_SUCCESS',
    EDIT_SUB_DOMAIN_FAILURE: 'EDIT_SUB_DOMAIN_FAILURE',

    GET_LIST_DOCUMENT_START: 'GET_LIST_DOCUMENT_START',
    GET_LIST_DOCUMENT_SUCCESS: 'GET_LIST_DOCUMENT_SUCCESS',
    GET_LIST_DOCUMENT_FAILURE: 'GET_LIST_DOCUMENT_FAILURE',
    GET_ITEM_DOCUMENT_START: 'GET_ITEM_DOCUMENT_START',
    GET_ITEM_DOCUMENT_SUCCESS: 'GET_ITEM_DOCUMENT_SUCCESS',
    GET_ITEM_DOCUMENT_FAILURE: 'GET_ITEM_DOCUMENT_FAILURE',
    POST_DOCUMENT_START: 'POST_DOCUMENT_START',
    UPDATE_DOCUMENT_START: 'UPDATE_DOCUMENT_START',
    DELETE_DOCUMENT_START: 'DELETE_DOCUMENT_START',
    DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
    EDIT_DOCUMENT_SUCCESS: 'EDIT_DOCUMENT_SUCCESS',
    EDIT_DOCUMENT_FAILURE: 'EDIT_DOCUMENT_FAILURE',

    GET_LIST_PROGRAM_START: 'GET_LIST_PROGRAM_START',
    GET_LIST_PROGRAM_SUCCESS: 'GET_LIST_PROGRAM_SUCCESS',
    GET_LIST_PROGRAM_FAILURE: 'GET_LIST_PROGRAM_FAILURE',
    GET_ITEM_PROGRAM_START: 'GET_ITEM_PROGRAM_START',
    GET_ITEM_PROGRAM_SUCCESS: 'GET_ITEM_PROGRAM_SUCCESS',
    GET_ITEM_PROGRAM_FAILURE: 'GET_ITEM_PROGRAM_FAILURE',
    POST_PROGRAM_START: 'POST_PROGRAM_START',
    UPDATE_PROGRAM_START: 'UPDATE_PROGRAM_START',
    DELETE_PROGRAM_START: 'DELETE_PROGRAM_START',
    DELETE_PROGRAM_SUCCESS: 'DELETE_PROGRAM_SUCCESS',
    EDIT_PROGRAM_SUCCESS: 'EDIT_PROGRAM_SUCCESS',
    EDIT_PROGRAM_FAILURE: 'EDIT_PROGRAM_FAILURE',

    GET_LIST_COURSE_START: 'GET_LIST_COURSE_START',
    GET_LIST_COURSE_SUCCESS: 'GET_LIST_COURSE_SUCCESS',
    GET_LIST_COURSE_FAILURE: 'GET_LIST_COURSE_FAILURE',
    GET_ITEM_COURSE_START: 'GET_ITEM_COURSE_START',
    GET_ITEM_COURSE_SUCCESS: 'GET_ITEM_COURSE_SUCCESS',
    GET_ITEM_COURSE_FAILURE: 'GET_ITEM_COURSE_FAILURE',
    POST_COURSE_START: 'POST_COURSE_START',
    UPDATE_COURSE_START: 'UPDATE_COURSE_START',
    DELETE_COURSE_START: 'DELETE_COURSE_START',
    DELETE_COURSE_SUCCESS: 'DELETE_COURSE_SUCCESS',
    EDIT_COURSE_SUCCESS: 'EDIT_COURSE_SUCCESS',
    EDIT_COURSE_FAILURE: 'EDIT_COURSE_FAILURE'
}