import { put, all, takeEvery, takeLatest} from 'redux-saga/effects';
import { unregisterField, change } from 'redux-form';
import * as actions from '../../actions';
import { FormAppTypes } from '../../actions/actions.types';


export function* editFormDataSaga (action) {
    const dataEdit = action.payload;
    yield put(actions.editFormDataSuccess(dataEdit))
}

export function* editModalFormDataSaga (action) {
    const dataEdit = action.payload;
    yield put(actions.editModalFormDataSuccess(dataEdit))
}

export function* editFormDataBuilderSaga (action) {
    const dataEditBuilder = action.payload;
    yield put(actions.editFormDataBuilderSuccess(dataEditBuilder))
}

export function* rxformDeactivateFieldSaga (action) {
    const { formName, fieldName } = action.payload;
    //set value empty untouch
   //dispatch(autofill(ownProps.form, field, undefined))
/*     yield put(change(formName, fieldName, null));
   yield put(unregisterField(formName, fieldName));  */
    yield put(change(formName, fieldName, undefined))
   yield put(unregisterField(formName, fieldName)); 
  
}

export function* rxformSetFieldValueSaga (action) {
    const { formName, fieldName, value } = action.payload;
    yield put(change(formName, fieldName, value));
}

export function* watchFormApp () {
    yield all([
        takeEvery(FormAppTypes.EDIT_FORM_DATA_START, editFormDataSaga),
        takeEvery(FormAppTypes.EDIT_FORM_DATA_BUILDER_START, editFormDataBuilderSaga),
        takeEvery(FormAppTypes.RXFORM_DEACTIVATE_FIELD_START, rxformDeactivateFieldSaga),
        takeLatest(FormAppTypes.RXFORM_SET_FIELD_VALUE_START, rxformSetFieldValueSaga),
        takeEvery(FormAppTypes.EDIT_MODAL_FORM_DATA_START, editModalFormDataSaga),
    ]);
}