import { AuthActionTypes } from '../../actions/actions.types';

const initialState = {
    token: null,
    userProfil: null,
    isAuthenticated: false,
    loading: true,
    error: null
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case AuthActionTypes.SIGN_IN_SUCCESS:
            return {
                ...state,
                token: payload,
                isAuthenticated: true,
                loading: false
            };
        case AuthActionTypes.GET_USER_SESSION:
            return {
                ...state,
                userProfil: payload,
                isAuthenticated: true,
                loading: false
            };
        case AuthActionTypes.GET_USER_SESSION_FAIL:
        case AuthActionTypes.SIGN_IN_FAILURE:
        case AuthActionTypes.SIGN_OUT_FAILURE:
            return {
                ...state,
                token: null,
                userProfil: null,
                isAuthenticated: false,
                loading: false,
                error: payload,
            };
        case AuthActionTypes.SIGN_OUT_SUCCESS:
            return {
                ...state,
                token: null,
                userProfil: null,
                isAuthenticated: false,
                loading: false
            };
        default:
            return state;
    }

}
