import { ScolarTypes } from '../actions.types';

export const getListInstitutionStart = () => ({
    type: ScolarTypes.GET_LIST_INSTITUTION_START
});

export const getListInstitutionSuccess = (listInstitution) =>({
    type: ScolarTypes.GET_LIST_INSTITUTION_SUCCESS,
    payload: listInstitution
});

export const getListInstitutionFailure = (error) =>({
    type: ScolarTypes.GET_LIST_INSTITUTION_FAILURE,
    payload: error
});

export const getItemInstitutionStart = (id) => ({
    type: ScolarTypes.GET_ITEM_INSTITUTION_START,
    payload: id
});

export const getItemInstitutionSuccess = (itemInstitution) => ({
    type: ScolarTypes.GET_ITEM_INSTITUTION_SUCCESS,
    payload: itemInstitution
});

export const getItemInstitutionFailure = (error) => ({
    type: ScolarTypes.GET_ITEM_INSTITUTION_FAILURE,
    payload: error
});

export const deleteInstitutionStart = (id) => ({
    type: ScolarTypes.DELETE_INSTITUTION_START,
    payload: id
});

export const deleteInstitutionSuccess = (id) => ({
    type: ScolarTypes.DELETE_INSTITUTION_SUCCESS,
    payload: id
});

export const postInstitutionStart = (formData, history) => ({
    type: ScolarTypes.POST_INSTITUTION_START,
    payload: {formData, history}
});

export const updateInstitutionStart = (id, formData) => ({
    type: ScolarTypes.UPDATE_INSTITUTION_START,
    payload: { id, formData }
});

export const editInstitutionSuccess = (domainEdit) => ({
    type: ScolarTypes.EDIT_INSTITUTION_SUCCESS,
    payload: domainEdit
});

export const editInstitutionFailure = (error) => ({
    type: ScolarTypes.EDIT_INSTITUTION_FAILURE,
    payload: error
});

export const getListSpecialityStart = (param) => ({
    type: ScolarTypes.GET_LIST_SPECIALITY_START,
    payload: param
});

export const getListSpecialitySuccess = (listSpeciality) =>({
    type: ScolarTypes.GET_LIST_SPECIALITY_SUCCESS,
    payload: listSpeciality
});

export const getListSpecialityFailure = (error) =>({
    type: ScolarTypes.GET_LIST_SPECIALITY_FAILURE,
    payload: error
});

export const getItemSpecialityStart = (id) => ({
    type: ScolarTypes.GET_ITEM_SPECIALITY_START,
    payload: id
});

export const getItemSpecialitySuccess = (itemSpeciality) => ({
    type: ScolarTypes.GET_ITEM_SPECIALITY_SUCCESS,
    payload: itemSpeciality
});

export const getItemSpecialityFailure = (error) => ({
    type: ScolarTypes.GET_ITEM_SPECIALITY_FAILURE,
    payload: error
});

export const deleteSpecialityStart = (id) => ({
    type: ScolarTypes.DELETE_SPECIALITY_START,
    payload: id
});

export const deleteSpecialitySuccess = (id) => ({
    type: ScolarTypes.DELETE_SPECIALITY_SUCCESS,
    payload: id
});

export const postSpecialityStart = (formData, idinstitution, history) => ({
    type: ScolarTypes.POST_SPECIALITY_START,
    payload: {formData, idinstitution, history}
});

export const updateSpecialityStart = (id, formData, paramQuery) => ({
    type: ScolarTypes.UPDATE_SPECIALITY_START,
    payload: { id, formData, paramQuery }
});

export const editSpecialitySuccess = (domainEdit) => ({
    type: ScolarTypes.EDIT_SPECIALITY_SUCCESS,
    payload: domainEdit
});

export const editSpecialityFailure = (error) => ({
    type: ScolarTypes.EDIT_SPECIALITY_FAILURE,
    payload: error
});

export const getListGradeStart = (param) => ({
    type: ScolarTypes.GET_LIST_GRADE_START,
    payload: param
});

export const getListGradeSuccess = (listGrade) =>({
    type: ScolarTypes.GET_LIST_GRADE_SUCCESS,
    payload: listGrade
});

export const getListGradeFailure = (error) =>({
    type: ScolarTypes.GET_LIST_GRADE_FAILURE,
    payload: error
});

export const getItemGradeStart = (id) => ({
    type: ScolarTypes.GET_ITEM_GRADE_START,
    payload: id
});

export const getItemGradeSuccess = (itemGrade) => ({
    type: ScolarTypes.GET_ITEM_GRADE_SUCCESS,
    payload: itemGrade
});

export const getItemGradeFailure = (error) => ({
    type: ScolarTypes.GET_ITEM_GRADE_FAILURE,
    payload: error
});

export const deleteGradeStart = (id) => ({
    type: ScolarTypes.DELETE_GRADE_START,
    payload: id
});

export const deleteGradeSuccess = (id) => ({
    type: ScolarTypes.DELETE_GRADE_SUCCESS,
    payload: id
});

export const postGradeStart = (formData, idspeciality, history) => ({
    type: ScolarTypes.POST_GRADE_START,
    payload: {formData, idspeciality, history}
});

export const updateGradeStart = (id, formData, paramQuery) => ({
    type: ScolarTypes.UPDATE_GRADE_START,
    payload: { id, formData, paramQuery }
});

export const editGradeSuccess = (domainEdit) => ({
    type: ScolarTypes.EDIT_GRADE_SUCCESS,
    payload: domainEdit
});

export const editGradeFailure = (error) => ({
    type: ScolarTypes.EDIT_GRADE_FAILURE,
    payload: error
});

export const getListDegreeStart = () => ({
    type: ScolarTypes.GET_LIST_DEGREE_START
});

export const getListDegreeSuccess = (listDegree) =>({
    type: ScolarTypes.GET_LIST_DEGREE_SUCCESS,
    payload: listDegree
});

export const getListDegreeFailure = (error) =>({
    type: ScolarTypes.GET_LIST_DEGREE_FAILURE,
    payload: error
});

export const getItemDegreeStart = (id) => ({
    type: ScolarTypes.GET_ITEM_DEGREE_START,
    payload: id
});

export const getItemDegreeSuccess = (itemDegree) => ({
    type: ScolarTypes.GET_ITEM_DEGREE_SUCCESS,
    payload: itemDegree
});

export const getItemDegreeFailure = (error) => ({
    type: ScolarTypes.GET_ITEM_DEGREE_FAILURE,
    payload: error
});

export const deleteDegreeStart = (id) => ({
    type: ScolarTypes.DELETE_DEGREE_START,
    payload: id
});

export const deleteDegreeSuccess = (id) => ({
    type: ScolarTypes.DELETE_DEGREE_SUCCESS,
    payload: id
});

export const postDegreeStart = (formData, history) => ({
    type: ScolarTypes.POST_DEGREE_START,
    payload: {formData, history}
});

export const updateDegreeStart = (id, formData, history) => ({
    type: ScolarTypes.UPDATE_DEGREE_START,
    payload: { id, formData, history }
});

export const editDegreeSuccess = (domainEdit) => ({
    type: ScolarTypes.EDIT_DEGREE_SUCCESS,
    payload: domainEdit
});

export const editDegreeFailure = (error) => ({
    type: ScolarTypes.EDIT_DEGREE_FAILURE,
    payload: error
});

/******************************************************UE************************* */

export const getListUeStart = (param) => ({
    type: ScolarTypes.GET_LIST_UE_START,
    payload: param
});

export const getListUeSuccess = (listUe) =>({
    type: ScolarTypes.GET_LIST_UE_SUCCESS,
    payload: listUe
});

export const getListUeFailure = (error) =>({
    type: ScolarTypes.GET_LIST_UE_FAILURE,
    payload: error
});

export const getItemUeStart = (id, param) => ({
    type: ScolarTypes.GET_ITEM_UE_START,
    payload: {id, param}
});

export const getItemUeSuccess = (itemUe) => ({
    type: ScolarTypes.GET_ITEM_UE_SUCCESS,
    payload: itemUe
});

export const getItemUeFailure = (error) => ({
    type: ScolarTypes.GET_ITEM_UE_FAILURE,
    payload: error
});

export const deleteUeStart = (id) => ({
    type: ScolarTypes.DELETE_UE_START,
    payload: id
});

export const deleteUeSuccess = (id) => ({
    type: ScolarTypes.DELETE_UE_SUCCESS,
    payload: id
});

export const postUeStart = (formData, idparent,  history) => ({
    type: ScolarTypes.POST_UE_START,
    payload: {formData, history, idparent}
});

export const updateUeStart = (id, formData, param) => ({
    type: ScolarTypes.UPDATE_UE_START,
    payload: { id, formData, param }
});

export const editUeSuccess = (domainEdit) => ({
    type: ScolarTypes.EDIT_UE_SUCCESS,
    payload: domainEdit
});

export const editUeFailure = (error) => ({
    type: ScolarTypes.EDIT_UE_FAILURE,
    payload: error
});

/***********Student*********** */

export const getListStudentStart = (param) => ({
    type: ScolarTypes.GET_LIST_STUDENT_START,
    payload: param
});

export const getListStudentSuccess = (listStudent) =>({
    type: ScolarTypes.GET_LIST_STUDENT_SUCCESS,
    payload: listStudent
});

export const getListStudentFailure = (error) =>({
    type: ScolarTypes.GET_LIST_STUDENT_FAILURE,
    payload: error
});

export const getItemStudentStart = (id) => ({
    type: ScolarTypes.GET_ITEM_STUDENT_START,
    payload: id
});

export const getItemStudentSuccess = (itemStudent) => ({
    type: ScolarTypes.GET_ITEM_STUDENT_SUCCESS,
    payload: itemStudent
});

export const getItemStudentFailure = (error) => ({
    type: ScolarTypes.GET_ITEM_STUDENT_FAILURE,
    payload: error
});

export const deleteStudentStart = (id) => ({
    type: ScolarTypes.DELETE_STUDENT_START,
    payload: id
});

export const deleteStudentSuccess = (id) => ({
    type: ScolarTypes.DELETE_STUDENT_SUCCESS,
    payload: id
});

export const postStudentStart = (formData, idgrade, history) => ({
    type: ScolarTypes.POST_STUDENT_START,
    payload: {formData, idgrade, history}
});

export const updateStudentStart = (id, formData, typeEdit, param) => ({
    type: ScolarTypes.UPDATE_STUDENT_START,
    payload: { id, formData, typeEdit, param }
});

export const editStudentSuccess = (studentEdit) => ({
    type: ScolarTypes.EDIT_STUDENT_SUCCESS,
    payload: studentEdit
});

export const editStudentFailure = (error) => ({
    type: ScolarTypes.EDIT_STUDENT_FAILURE,
    payload: error
});

/**************Teacher***************** */

export const getListTeacherStart = () => ({
    type: ScolarTypes.GET_LIST_TEACHER_START
});

export const getListTeacherSuccess = (listTeacher) =>({
    type: ScolarTypes.GET_LIST_TEACHER_SUCCESS,
    payload: listTeacher
});

export const getListTeacherFailure = (error) =>({
    type: ScolarTypes.GET_LIST_TEACHER_FAILURE,
    payload: error
});

export const getItemTeacherStart = (id) => ({
    type: ScolarTypes.GET_ITEM_TEACHER_START,
    payload: id
});

export const getItemTeacherSuccess = (itemTeacher) => ({
    type: ScolarTypes.GET_ITEM_TEACHER_SUCCESS,
    payload: itemTeacher
});

export const getItemTeacherFailure = (error) => ({
    type: ScolarTypes.GET_ITEM_TEACHER_FAILURE,
    payload: error
});

export const deleteTeacherStart = (id) => ({
    type: ScolarTypes.DELETE_TEACHER_START,
    payload: id
});

export const deleteTeacherSuccess = (id) => ({
    type: ScolarTypes.DELETE_TEACHER_SUCCESS,
    payload: id
});

export const postTeacherStart = (formData, history) => ({
    type: ScolarTypes.POST_TEACHER_START,
    payload: {formData, history}
});

export const updateTeacherStart = (id, formData, typeEdit) => ({
    type: ScolarTypes.UPDATE_TEACHER_START,
    payload: { id, formData, typeEdit }
});

export const editTeacherSuccess = (teacherEdit) => ({
    type: ScolarTypes.EDIT_TEACHER_SUCCESS,
    payload: teacherEdit
});

export const editTeacherFailure = (error) => ({
    type: ScolarTypes.EDIT_TEACHER_FAILURE,
    payload: error
});

/*************************************messager********************************* */
export const getListMessageStart = (param) => ({
    type: ScolarTypes.GET_LIST_MESSAGE_START,
    payload: param
});

export const getListMessageSuccess = (listMessage) =>({
    type: ScolarTypes.GET_LIST_MESSAGE_SUCCESS,
    payload: listMessage
});

export const getListMessageNotReadStart = (param) => ({
    type: ScolarTypes.GET_LIST_MESSAGE_NOT_READ_START,
    payload: param
});

export const getListMessageNotReadSuccess = (listMessageNotRead) =>({
    type: ScolarTypes.GET_LIST_MESSAGE_NOT_READ_SUCCESS,
    payload: listMessageNotRead
});

export const getListMessageFailure = (error) =>({
    type: ScolarTypes.GET_LIST_MESSAGE_FAILURE,
    payload: error
});

export const getItemMessageStart = (id) => ({
    type: ScolarTypes.GET_ITEM_MESSAGE_START,
    payload: id
});

export const getItemMessageSuccess = (itemMessage) => ({
    type: ScolarTypes.GET_ITEM_MESSAGE_SUCCESS,
    payload: itemMessage
});

export const getItemMessageFailure = (error) => ({
    type: ScolarTypes.GET_ITEM_MESSAGE_FAILURE,
    payload: error
});

export const deleteMessageStart = (id) => ({
    type: ScolarTypes.DELETE_MESSAGE_START,
    payload: id
});

export const deleteMessageSuccess = (id) => ({
    type: ScolarTypes.DELETE_MESSAGE_SUCCESS,
    payload: id
});


export const updateMessageStart = (id, formData, typeEdit, param) => ({
    type: ScolarTypes.UPDATE_MESSAGE_START,
    payload: { id, formData, typeEdit, param }
});

export const editMessageSuccess = (studentEdit) => ({
    type: ScolarTypes.EDIT_MESSAGE_SUCCESS,
    payload: studentEdit
});

export const editMessageFailure = (error) => ({
    type: ScolarTypes.EDIT_MESSAGE_FAILURE,
    payload: error
});

export const getListStatisticScolStart = (param) => ({
    type: ScolarTypes.GET_LIST_STATISTIC_SCOL_START,
    payload: param
});

export const getListStatisticScolSuccess = (listStatisticScol) =>({
    type: ScolarTypes.GET_LIST_STATISTIC_SCOL_SUCCESS,
    payload: listStatisticScol
});

export const getListStatisticScolFailure = (error) =>({
    type: ScolarTypes.GET_LIST_STATISTIC_SCOL_FAILURE,
    payload: error
});