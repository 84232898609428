import { FormAppTypes } from '../../actions/actions.types';

const initialState = {
    editFormSimple: null,
    editFormBuilder: null,
    editFormSimpleModal: null,
    loading: true
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case FormAppTypes.EDIT_FORM_DATA_SUCCCESS: 
                return {
                    ...state,
                    editFormSimple: payload,
                    loading: false
                };
        case FormAppTypes.EDIT_MODAL_FORM_DATA_SUCCCESS:
            return {
                ...state,
                editFormSimpleModal: payload,
                loading: false
            };
        case FormAppTypes.EDIT_FORM_DATA_BUILDER_SUCCESS:
            return {
                ...state,
                editFormBuilder: payload,
                loading: false
            };
        default:
            return state;      
    }
}