import { createMuiTheme } from '@material-ui/core/styles';

 const Theme = createMuiTheme({
    breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 992,
          lg: 1280,
          xl: 1920,
        },
      },
/*     palette: {
        primary: {
          main: "#20a8d8",
          light: '#3c44b126'
        },
        secondary: {
          main: "#f83245",
          light: '#f8324526'
        },
        background: {
          default: "#f4f5fd"
        },
      } */

});

export default Theme;


/**
 *     palette: {
        primary: {
            main: '#2196f3'
        },
        secondary: {
            main: '#ff5722'
        }
    }
 */