import { NotificationTypes } from '../actions.types';


export const alertNotificationStart = (message, typeMessage, expirationTime = 5000) => ({
    type: NotificationTypes.ALERT_NOTIFICATION_START,
    payload: {message, typeMessage, expirationTime}
});

export const alertNotificationSucces = (payloadMsg, id) => ({
    type: NotificationTypes.ALERT_NOTIFICATION_SUCCESS,
    payload: {...payloadMsg, id}
});

export const removeAlertNotification = (id) => ({
    type: NotificationTypes.REMOVE_ALERT_NOTIFICATION,
    payload: id
});