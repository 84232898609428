import {takeEvery, takeLatest, put, all, call} from 'redux-saga/effects';
import { AdminUserTypes } from '../../actions/actions.types';
import * as actions from '../../actions/index';
import axios from '../../../config/axios/axios-instance';
import { headerContentTypeJson } from '../../../config/auth/authentication';
import { stringError } from '../../../utils/error/apiErrorMsg';
import { adminUserViewMsg } from '../../../config/view-msg/sagas.view-msg';
import {LINK_LIST_ADMIN} from '../../../routes';



export function* getListUserAdminSaga() {
    try {
        const response = yield axios.get('/api/manage-user/list-administratif');
        yield put(actions.getListAdminSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListAdminFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    } 
}


export function* getItemAdminSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/manage-user/profil-administratif/${id}`);
        yield put(actions.getItemAdminSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemAdminFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
} 

export function* postAdminSaga (action) {
    try {
        const {formPostData, history}  = action.payload;

        const response = yield axios.post('/api/manage-user/create-administratif', formPostData, headerContentTypeJson());
        yield put(actions.editSuccess(response.data));
        const msgSuccess = adminUserViewMsg['successCreateAdmin'];
        yield put(actions.alertNotificationStart(msgSuccess, 'success'));
        yield call([history, history.push],LINK_LIST_ADMIN);
        
    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error', 5000));
    }
}

export function* updateAdminSaga (action) {
    try {
        const { formUpdateData, typeEdit, id, history} = action.payload;
        let response = '';
        let msgSuccess = '';
        if (typeEdit === 'modify-administratif') { 
            response = yield axios.put(`/api/manage-user/modify-administratif/${id}`, formUpdateData, headerContentTypeJson());
            msgSuccess = adminUserViewMsg['successModifyAdminInfo']; 
            yield put(actions.getListAdminStart());
        } else if (typeEdit === 'add-user-app' || typeEdit === 'modify-user-app') {
            response = yield axios.put(`/api/manage-user/modify-connect-app-name/${id}`, formUpdateData, headerContentTypeJson());
            msgSuccess = adminUserViewMsg['successModifyAccessApp'];

        } else if (typeEdit === 'modify-password') {
            response = yield axios.put(`/api/manage-user/modify-connect-app-pass/${id}`, formUpdateData, headerContentTypeJson());
            msgSuccess = adminUserViewMsg['successModifyPass']; 

        } else if (typeEdit === 'modify-user-info') {
            response = yield axios.put(`/api/manage-user/modify-user/${id}`, formUpdateData, headerContentTypeJson());
            msgSuccess = adminUserViewMsg['successModifyProfilAdmin'];  
        } else if (typeEdit === 'modify-myprofile') {
            response = yield axios.put(`/api/myprofile/modify-connect-app-pass/${id}`, formUpdateData, headerContentTypeJson());
            msgSuccess = adminUserViewMsg['successModifyProfil']; 
        } 
/*         else {
            const errorNotValid = 'Une erreur est survenue';
            yield put(actions.editFailure(errorNotValid));
            yield put(actions.alertNotificationStart(errorNotValid, 'error'));
        } */
        //send response data updating  to itemAdminEdit reducer        
        yield put(actions.editSuccess(response.data));
        yield put(actions.alertNotificationStart(msgSuccess, 'success'));

        if (typeEdit === 'modify-user-info') {  // is not modal, after edit return list admin page
            yield call([history, history.push],LINK_LIST_ADMIN);
        }

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}


export function* deleteAdminSaga (action) {

    try {
        const id = action.payload;
         yield axios.delete(`/api​/manage-user​/delete-administratif​/${id}`);
        yield put(actions.deleteAdminSuccess(id));
        const msgSuccess = adminUserViewMsg['successDeleteProfil']; 
        yield put(actions.alertNotificationStart(msgSuccess, 'success'));
    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* watchAdminUser () {
    yield all([
        takeEvery(AdminUserTypes.GET_ITEM_ADMIN_START, getItemAdminSaga),
        takeEvery(AdminUserTypes.POST_ADMIN_START, postAdminSaga),
        takeEvery(AdminUserTypes.UPDATE_ADMIN_START, updateAdminSaga),
        takeEvery(AdminUserTypes.DELETE_ADMIN_START, deleteAdminSaga),
        takeLatest(AdminUserTypes.GET_LIST_ADMIN_START, getListUserAdminSaga),
    ])
}