import { ElearningTypes } from "../actions.types";



export const getListOnDomainStart = () => ({
    type: ElearningTypes.GET_LIST_ONDOMAIN_START
});

export const getListOnDomainSuccess = (listOnDomain) =>({
    type: ElearningTypes.GET_LIST_ONDOMAIN_SUCCESS,
    payload: listOnDomain
});

export const getListOnDomainFailure = (error) =>({
    type: ElearningTypes.GET_LIST_ONDOMAIN_FAILURE,
    payload: error
});

export const getItemOnDomainStart = (id) => ({
    type: ElearningTypes.GET_ITEM_ONDOMAIN_START,
    payload: id
});

export const getItemOnDomainSuccess = (itemOnDomain) => ({
    type: ElearningTypes.GET_ITEM_ONDOMAIN_SUCCESS,
    payload: itemOnDomain
});

export const getItemOnDomainFailure = (error) => ({
    type: ElearningTypes.GET_ITEM_ONDOMAIN_FAILURE,
    payload: error
});

export const deleteOnDomainStart = (id) => ({
    type: ElearningTypes.DELETE_ONDOMAIN_START,
    payload: id
});

export const deleteOnDomainSuccess = (id) => ({
    type: ElearningTypes.DELETE_ONDOMAIN_SUCCESS,
    payload: id
});

export const postOnDomainStart = (formData, history) => ({
    type: ElearningTypes.POST_ONDOMAIN_START,
    payload: {formData, history}
});

export const updateOnDomainStart = (id, formData, typeEdit) => ({
    type: ElearningTypes.UPDATE_ONDOMAIN_START,
    payload: { id, formData, typeEdit }
});

export const editOnDomainSuccess = (teacherEdit) => ({
    type: ElearningTypes.EDIT_ONDOMAIN_SUCCESS,
    payload: teacherEdit
});

export const editOnDomainFailure = (error) => ({
    type: ElearningTypes.EDIT_ONDOMAIN_FAILURE,
    payload: error
});

export const getListSubDomainStart = () => ({
    type: ElearningTypes.GET_LIST_SUB_DOMAIN_START
});

export const getListSubDomainSuccess = (listSubDomain) =>({
    type: ElearningTypes.GET_LIST_SUB_DOMAIN_SUCCESS,
    payload: listSubDomain
});

export const getListSubDomainFailure = (error) =>({
    type: ElearningTypes.GET_LIST_SUB_DOMAIN_FAILURE,
    payload: error
});

export const getItemSubDomainStart = (id) => ({
    type: ElearningTypes.GET_ITEM_SUB_DOMAIN_START,
    payload: id
});

export const getItemSubDomainSuccess = (itemSubDomain) => ({
    type: ElearningTypes.GET_ITEM_SUB_DOMAIN_SUCCESS,
    payload: itemSubDomain
});

export const getItemSubDomainFailure = (error) => ({
    type: ElearningTypes.GET_ITEM_SUB_DOMAIN_FAILURE,
    payload: error
});

export const deleteSubDomainStart = (id) => ({
    type: ElearningTypes.DELETE_SUB_DOMAIN_START,
    payload: id
});

export const deleteSubDomainSuccess = (id) => ({
    type: ElearningTypes.DELETE_SUB_DOMAIN_SUCCESS,
    payload: id
});

export const postSubDomainStart = (formData, idondomain, history) => ({
    type: ElearningTypes.POST_SUB_DOMAIN_START,
    payload: {formData, idondomain, history}
});

export const updateSubDomainStart = (id, formData, typeEdit) => ({
    type: ElearningTypes.UPDATE_SUB_DOMAIN_START,
    payload: { id, formData, typeEdit }
});

export const editSubDomainSuccess = (teacherEdit) => ({
    type: ElearningTypes.EDIT_SUB_DOMAIN_SUCCESS,
    payload: teacherEdit
});

export const editSubDomainFailure = (error) => ({
    type: ElearningTypes.EDIT_SUB_DOMAIN_FAILURE,
    payload: error
});

export const getListDocumentStart = (param) => ({
    type: ElearningTypes.GET_LIST_DOCUMENT_START,
    payload: param
});

export const getListDocumentSuccess = (listDocument) =>({
    type: ElearningTypes.GET_LIST_DOCUMENT_SUCCESS,
    payload: listDocument
});

export const getListDocumentFailure = (error) =>({
    type: ElearningTypes.GET_LIST_DOCUMENT_FAILURE,
    payload: error
});

export const getItemDocumentStart = (id) => ({
    type: ElearningTypes.GET_ITEM_DOCUMENT_START,
    payload: id
});

export const getItemDocumentSuccess = (itemDocument) => ({
    type: ElearningTypes.GET_ITEM_DOCUMENT_SUCCESS,
    payload: itemDocument
});

export const getItemDocumentFailure = (error) => ({
    type: ElearningTypes.GET_ITEM_DOCUMENT_FAILURE,
    payload: error
});

export const deleteDocumentStart = (id) => ({
    type: ElearningTypes.DELETE_DOCUMENT_START,
    payload: id
});

export const deleteDocumentSuccess = (id) => ({
    type: ElearningTypes.DELETE_DOCUMENT_SUCCESS,
    payload: id
});

export const postDocumentStart = (formData, typeDoc, idsubdomain, history) => ({
    type: ElearningTypes.POST_DOCUMENT_START,
    payload: {formData, typeDoc, idsubdomain, history}
});
//payload: {formData, typeDoc, idsubdomain, history}
export const updateDocumentStart = (id, formData, typeDoc, paramQuery) => ({
    type: ElearningTypes.UPDATE_DOCUMENT_START,
    payload: { id, formData, typeDoc, paramQuery }
});

export const editDocumentSuccess = (teacherEdit) => ({
    type: ElearningTypes.EDIT_DOCUMENT_SUCCESS,
    payload: teacherEdit
});

export const editDocumentFailure = (error) => ({
    type: ElearningTypes.EDIT_DOCUMENT_FAILURE,
    payload: error
});


/**************portall actions********************* */
export const getListProgramStart = (param, id ) => ({
    type: ElearningTypes.GET_LIST_PROGRAM_START,
    payload: {param, id}
});

export const getListProgramSuccess = (listProgram) =>({
    type: ElearningTypes.GET_LIST_PROGRAM_SUCCESS,
    payload: listProgram
});

export const getListProgramFailure = (error) =>({
    type: ElearningTypes.GET_LIST_PROGRAM_FAILURE,
    payload: error
});

export const getItemProgramStart = (id) => ({
    type: ElearningTypes.GET_ITEM_PROGRAM_START,
    payload: id
});

export const getItemProgramSuccess = (itemProgram) => ({
    type: ElearningTypes.GET_ITEM_PROGRAM_SUCCESS,
    payload: itemProgram
});

export const getItemProgramFailure = (error) => ({
    type: ElearningTypes.GET_ITEM_PROGRAM_FAILURE,
    payload: error
});

export const deleteProgramStart = (id) => ({
    type: ElearningTypes.DELETE_PROGRAM_START,
    payload: id
});

export const deleteProgramSuccess = (id) => ({
    type: ElearningTypes.DELETE_PROGRAM_SUCCESS,
    payload: id
});

export const postProgramStart = (formData, history, context, id) => ({
    type: ElearningTypes.POST_PROGRAM_START,
    payload: {formData, history, context, id}
});

export const updateProgramStart = (id, formData, param, idue) => ({
    type: ElearningTypes.UPDATE_PROGRAM_START,
    payload: { id, formData,  param, idue }
});

export const editProgramSuccess = (teacherEdit) => ({
    type: ElearningTypes.EDIT_PROGRAM_SUCCESS,
    payload: teacherEdit
});

export const editProgramFailure = (error) => ({
    type: ElearningTypes.EDIT_PROGRAM_FAILURE,
    payload: error
});


export const getListCourseStart = (param, id) => ({
    type: ElearningTypes.GET_LIST_COURSE_START,
    payload: {param, id}
});

export const getListCourseSuccess = (listCourse) =>({
    type: ElearningTypes.GET_LIST_COURSE_SUCCESS,
    payload: listCourse
});

export const getListCourseFailure = (error) =>({
    type: ElearningTypes.GET_LIST_COURSE_FAILURE,
    payload: error
});

export const getItemCourseStart = (id) => ({
    type: ElearningTypes.GET_ITEM_COURSE_START,
    payload: id
});

export const getItemCourseSuccess = (itemCourse) => ({
    type: ElearningTypes.GET_ITEM_COURSE_SUCCESS,
    payload: itemCourse
});

export const getItemCourseFailure = (error) => ({
    type: ElearningTypes.GET_ITEM_COURSE_FAILURE,
    payload: error
});

export const deleteCourseStart = (id) => ({
    type: ElearningTypes.DELETE_COURSE_START,
    payload: id
});

export const deleteCourseSuccess = (id) => ({
    type: ElearningTypes.DELETE_COURSE_SUCCESS,
    payload: id
});

export const postCourseStart = (formData, typeDoc, context, history, id, idprogram) => ({
    type: ElearningTypes.POST_COURSE_START,
    payload: {formData, typeDoc, context, idprogram, id, history}
});
//payload: {formData, typeDoc, idsubdomain, history}
export const updateCourseStart = (id, formData, typeDoc, context, idparent) => ({
    type: ElearningTypes.UPDATE_COURSE_START,
    payload: { id, formData, typeDoc,context, idparent }
});

export const editCourseSuccess = (teacherEdit) => ({
    type: ElearningTypes.EDIT_COURSE_SUCCESS,
    payload: teacherEdit
});

export const editCourseFailure = (error) => ({
    type: ElearningTypes.EDIT_COURSE_FAILURE,
    payload: error
});