import { all, call } from 'redux-saga/effects';

import { watchAuth } from './auth/auth.sagas';
import { watchNotification } from './notification/notification.sagas';
import { watchAdminUser } from './admin-user/admin-user.sagas';
import { watchFormApp } from './form-app/form-app.sagas';
import { watchWebSite } from './web-site/web-site.sagas';
import { watchScolar } from './scolar/scolar.sagas';
import { watchElearning } from './e-learning/e-learning.sagas';

export default function* rootSaga() {
    yield all([call(watchAuth), call(watchNotification), call(watchAdminUser), call(watchFormApp), call(watchWebSite), call(watchScolar), call(watchElearning)]);
}