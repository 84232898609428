import { takeEvery, put, all, call } from 'redux-saga/effects';
import {  ElearningTypes } from '../../actions/actions.types';
import * as actions from '../../actions/index';
import axios from '../../../config/axios/axios-instance';
import { headerContentTypeJson, headerContentTypeFormData } from '../../../config/auth/authentication';
import { stringError } from '../../../utils/error/apiErrorMsg';
import { eLearningViewMsg  } from '../../../config/view-msg/sagas.view-msg';
import { LINK_LIST_ON_DOMAIN, LINK_LIST_SUB_DOMAIN_SHARE, LINK_LIST_DOCUMENT_SHARE, LINK_LIST_COURSE_UE_SHARE, LINK_LIST_PROGRAM_UE_SHARE, LINK_SCOL_LIST_COURSE_SHARE } from '../../../routes';

import { LINK_LIST_PROGRAM, LINK_LIST_COURSE_SHARE, LINK_PORTAL_LIST_COURSE_UE_SHARE, LINK_PORTAL_LIST_PROGRAM_UE_SHARE}  from '../../../routes-portal';

export function* getListOnDomainSaga() {
    try {
        const response = yield axios.get('/api/gestion-scolarite/list-domain');

        yield put(actions.getListOnDomainSuccess(response.data));

    } catch (error) {
       
        const errorMsg = stringError(error);
        yield put(actions.getListOnDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemOnDomainSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-scolarite/domain/${id}`);
        yield put(actions.getItemOnDomainSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemOnDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deleteOnDomainSaga(action) {
    try {
        const id = action.payload;
        yield axios.delete(`/api/gestion-scolarite/delete-domain/${id}`);
        yield put(actions.deleteOnDomainSuccess(id));
        yield put(actions.alertNotificationStart(eLearningViewMsg['successDeleteOnDomain'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editOnDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postOnDomainSaga(action) {
    try {
        const { formData, history } = action.payload;

        const response = yield axios.post('/api/gestion-scolarite/create-domain', formData, headerContentTypeJson());

        yield put(actions.editOnDomainSuccess(response.data));
        yield put(actions.alertNotificationStart(eLearningViewMsg['successCreateOnDomain'], 'success'));

        yield call([history, history.push], LINK_LIST_ON_DOMAIN);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editOnDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* updateOnDomainSaga(action) {
    try {
        const { id, formData} = action.payload;
        const response = yield axios.put(`/api/gestion-scolarite/modify-domain/${id}`, formData, headerContentTypeJson());
        yield put(actions.editOnDomainSuccess(response.data));
        yield put(actions.alertNotificationStart(eLearningViewMsg['successUpdateOnDomain'], 'success'));
        yield put(actions.getListOnDomainStart());
       // yield call([history, history.push], LINK_LIST_DOMAIN);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editOnDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getListSubDomainSaga(action) {
    try {
        const paramQuery = action.payload;
        const response = yield axios.get('/api/gestion-scolarite/list-sub-domain', paramQuery);

        yield put(actions.getListSubDomainSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListSubDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemSubDomainSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-scolarite/sub-domain/${id}`);
        yield put(actions.getItemSubDomainSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemSubDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deleteSubDomainSaga(action) {
    try {
        const id = action.payload;
        yield axios.delete(`/api/gestion-scolarite/delete-sub-domain/${id}`);
        yield put(actions.deleteSubDomainSuccess(id));
        yield put(actions.alertNotificationStart(eLearningViewMsg['successDeleteSubDomain'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editSubDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postSubDomainSaga(action) {
    try {
        const { formData, history, idondomain } = action.payload;

        const response = yield axios.post('/api/gestion-scolarite/create-sub-domain', formData, headerContentTypeJson());

        yield put(actions.editSubDomainSuccess(response.data));
        yield put(actions.alertNotificationStart(eLearningViewMsg['successCreateSubDomain'], 'success'));

       const urlListPage = `${LINK_LIST_SUB_DOMAIN_SHARE}/${idondomain}`;
        yield call([history, history.push], urlListPage);
        //yield call([history, history.push], LINK_LIST_DOMAIN);

    } catch (error) {
     
        const errorMsg = stringError(error);
        yield put(actions.editSubDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* updateSubDomainSaga(action) {
    try {
        const { id, formData, paramQuery } = action.payload;
        const response = yield axios.put(`/api/gestion-scolarite/modify-sub-domain/${id}`, formData, headerContentTypeJson());
        yield put(actions.editSubDomainSuccess(response.data));
        yield put(actions.alertNotificationStart(eLearningViewMsg['successUpdateSubDomain'], 'success'));

        yield put(actions.getListSubDomainStart(paramQuery));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editSubDomainFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getListDocumentSaga(action) {
    try {
        const paramQuery = action.payload;
        const response = yield axios.get('/api/gestion-scolarite/list-document', paramQuery);
        yield put(actions.getListDocumentSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListDocumentFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemDocumentSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/gestion-scolarite/document/${id}`);
        yield put(actions.getItemDocumentSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemDocumentFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deleteDocumentSaga(action) {
    try {
        const id = action.payload;
        yield axios.delete(`/api/gestion-scolarite/delete-document/${id}`);
        yield put(actions.deleteDocumentSuccess(id));
        yield put(actions.alertNotificationStart(eLearningViewMsg['successDeleteDocument'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editDocumentFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postDocumentSaga(action) {
    try {
        const { formData, history, idsubdomain, typeDoc } = action.payload;

        let response = '';
        if (typeDoc === 'file') {
            response = yield axios.post('/api/gestion-scolarite/create-document-file', formData, headerContentTypeFormData());
        } else {
            response = yield axios.post('/api/gestion-scolarite/create-document', formData, headerContentTypeJson());
        }
        

        yield put(actions.editDocumentSuccess(response.data));
        yield put(actions.alertNotificationStart(eLearningViewMsg['successCreateDocument'], 'success'));

        const urlListPage = `${LINK_LIST_DOCUMENT_SHARE}/${idsubdomain}`;
        yield call([history, history.push], urlListPage);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editDocumentFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* updateDocumentSaga(action) {
    try {
        const { id, formData, paramQuery, typeDoc } = action.payload;
        let response = '';
        if (typeDoc === 'file') {
            response = yield axios.post(`/api/gestion-scolarite/update-document-file/${id}`, formData, headerContentTypeFormData());
        } else {
            response = yield axios.put(`/api/gestion-scolarite/update-document/${id}`, formData, headerContentTypeJson());
        }
        

        yield put(actions.editDocumentSuccess(response.data));
        yield put(actions.alertNotificationStart(eLearningViewMsg['successUpdateDocument'], 'success'));
        yield put(actions.getListDocumentStart(paramQuery));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editDocumentFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}


export function* getListProgramSaga(action) {
    try {
        const {param, id} = action.payload;

        let response = {
            data: []
        };
        if (param === 'my-programs') {
            response = yield axios.get('/api/portal-digital/list-myprogram-not-ue');
        } else if (param === 'program-ue') {
            response = yield axios.get(`/api/portal-digital/list-program-ue/${id}`);
        } else if (param === 'program-not-ue') {
            response = yield axios.get('/api/portal-digital/list-program-not-ue');
        } else {
            response = yield axios.get('/api/portal-digital/list-all-program');
        }
         
        yield put(actions.getListProgramSuccess(response.data));

    } catch (error) {
      
        const errorMsg = stringError(error);
        yield put(actions.getListProgramFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemProgramSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/portal-digital/program/${id}`);
        yield put(actions.getItemProgramSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemProgramFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deleteProgramSaga(action) {
    try {
        const id = action.payload;
        yield axios.delete(`/api/portal-digital/delete-program/${id}`);
        yield put(actions.deleteProgramSuccess(id));
        yield put(actions.alertNotificationStart(eLearningViewMsg['successDeleteProgram'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editProgramFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postProgramSaga(action) {
    try {
        const { formData, context, id, history } = action.payload;

        const response = yield axios.post('/api/portal-digital/create-program', formData, headerContentTypeJson());

        yield put(actions.editProgramSuccess(response.data));
        yield put(actions.alertNotificationStart(eLearningViewMsg['successCreateProgram'], 'success'));

        let urlListPage = LINK_LIST_PROGRAM;
        if (context === 'scol-program-ue') {
            urlListPage = `${LINK_LIST_PROGRAM_UE_SHARE}/${id}`; 
        } else if (context === 'port-program-ue') {
            urlListPage  = `${LINK_PORTAL_LIST_PROGRAM_UE_SHARE}/${id}`; 
        }

        yield call([history, history.push], urlListPage);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editProgramFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* updateProgramSaga(action) {
    try {
        const { id, formData, param, idue} = action.payload;
        const response = yield axios.put(`/api/portal-digital/modify-program/${id}`, formData, headerContentTypeJson());
        yield put(actions.editProgramSuccess(response.data));
        yield put(actions.alertNotificationStart(eLearningViewMsg['successUpdateProgram'], 'success'));

        if (param && param === 'program-ue') {
            yield put(actions.getListProgramStart(param, idue));
        } else {
            yield put(actions.getListProgramStart('my-programs'));
        }        
       // yield call([history, history.push], LINK_LIST_DOMAIN);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editProgramFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getListCourseSaga(action) {
    try {
        const {param, id} = action.payload;
        let response = '';
        if (param === 'my-courses-noprogram') {
            response = yield axios.get('/api/portal-digital/list-mycourse-noprogram');
        } else if (param === 'course-program') {
            response = yield axios.get(`/api/portal-digital/list-course-program/${id}`);
        } else if (param === 'course-noprogram') {
            response = yield axios.get('/api/portal-digital/list-course-noprogram');
        } else if (param === 'course-ue') {
            response = yield axios.get(`/api/portal-digital/list-course-ue/${id}`);
        } else if (param === 'course-param') {
            response = yield axios.get('/api/portal-digital/list-course', param);
        } else if (param === 'course-program-byue') {
            response = yield axios.get(`/api/portal-digital/list-program-course-byue/${id}`);
        } else {
            response = yield axios.get('/api/portal-digital/list-all-course');
        }
        
        yield put(actions.getListCourseSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getListCourseFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* getItemCourseSaga(action) {
    try {
        const id = action.payload;
        const response = yield axios.get(`/api/portal-digital/course/${id}`);
        yield put(actions.getItemCourseSuccess(response.data));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.getItemCourseFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* deleteCourseSaga(action) {
    try {
        const id = action.payload;
        yield axios.delete(`/api/portal-digital/delete-course/${id}`);
        yield put(actions.deleteCourseSuccess(id));
        yield put(actions.alertNotificationStart(eLearningViewMsg['successDeleteCourse'], 'success'));

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editCourseFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* postCourseSaga(action) {
    try {
        const { formData, typeDoc, context, idprogram, id, history } = action.payload;

        let response = '';
        if (typeDoc === 'file') {
            response = yield axios.post('/api/portal-digital/create-course-file', formData, headerContentTypeFormData());
        } else {
            response = yield axios.post('/api/portal-digital/create-course', formData, headerContentTypeJson());
        }
        

        yield put(actions.editCourseSuccess(response.data));
        yield put(actions.alertNotificationStart(eLearningViewMsg['successCreateCourse'], 'success'));

        let urlListPage = LINK_LIST_COURSE_SHARE;
        if (context === 'program') {
            urlListPage = `${LINK_LIST_COURSE_SHARE}/${id}`;
        } else if (context === 'program-scol') {
            urlListPage = `${LINK_SCOL_LIST_COURSE_SHARE}/${id}`; 
        }  else if (context === 'scol-course-ue') {
            urlListPage = `${LINK_LIST_COURSE_UE_SHARE}/${id}`; 
        }  else if (context === 'port-course-ue') {
            urlListPage = `${LINK_PORTAL_LIST_COURSE_UE_SHARE}/${id}`; 
        } 
        
        yield call([history, history.push], urlListPage);

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editCourseFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}

export function* updateCourseSaga(action) {
    try {
        const { id, formData, typeDoc,context, idparent} = action.payload;
        let response = '';
        if (typeDoc === 'file') {
            response = yield axios.post(`/api/portal-digital/update-course-file/${id}`, formData, headerContentTypeFormData());
        } else {
            response = yield axios.put(`/api/portal-digital/update-course/${id}`, formData, headerContentTypeJson());
        }
        
        yield put(actions.editCourseSuccess(response.data));
        yield put(actions.alertNotificationStart(eLearningViewMsg['successUpdateCourse'], 'success'));

        if (context === 'program') {
            yield put(actions.getListCourseStart('course-program', idparent));
        }  else if (context === 'course-ue') {
            yield put(actions.getListCourseStart('course-ue', idparent));
        } else {
            yield put(actions.getListCourseStart('my-courses-noprogram'));
        }
     

    } catch (error) {
        const errorMsg = stringError(error);
        yield put(actions.editCourseFailure(errorMsg));
        yield put(actions.alertNotificationStart(errorMsg, 'error'));
    }
}



export function* watchElearning() {
    yield all([
        takeEvery(ElearningTypes.GET_LIST_ONDOMAIN_START, getListOnDomainSaga),
        takeEvery(ElearningTypes.GET_ITEM_ONDOMAIN_START, getItemOnDomainSaga),
        takeEvery(ElearningTypes.DELETE_ONDOMAIN_START, deleteOnDomainSaga),
        takeEvery(ElearningTypes.POST_ONDOMAIN_START, postOnDomainSaga),
        takeEvery(ElearningTypes.UPDATE_ONDOMAIN_START, updateOnDomainSaga),
        takeEvery(ElearningTypes.GET_LIST_SUB_DOMAIN_START, getListSubDomainSaga),
        takeEvery(ElearningTypes.GET_ITEM_SUB_DOMAIN_START, getItemSubDomainSaga),
        takeEvery(ElearningTypes.DELETE_SUB_DOMAIN_START, deleteSubDomainSaga),
        takeEvery(ElearningTypes.POST_SUB_DOMAIN_START, postSubDomainSaga),
        takeEvery(ElearningTypes.UPDATE_SUB_DOMAIN_START, updateSubDomainSaga),
        takeEvery(ElearningTypes.GET_LIST_DOCUMENT_START, getListDocumentSaga),
        takeEvery(ElearningTypes.GET_ITEM_DOCUMENT_START, getItemDocumentSaga),
        takeEvery(ElearningTypes.DELETE_DOCUMENT_START, deleteDocumentSaga),
        takeEvery(ElearningTypes.POST_DOCUMENT_START, postDocumentSaga),
        takeEvery(ElearningTypes.UPDATE_DOCUMENT_START, updateDocumentSaga),
        takeEvery(ElearningTypes.GET_LIST_PROGRAM_START, getListProgramSaga),
        takeEvery(ElearningTypes.GET_ITEM_PROGRAM_START, getItemProgramSaga),
        takeEvery(ElearningTypes.DELETE_PROGRAM_START, deleteProgramSaga),
        takeEvery(ElearningTypes.POST_PROGRAM_START, postProgramSaga),
        takeEvery(ElearningTypes.UPDATE_PROGRAM_START, updateProgramSaga),
        takeEvery(ElearningTypes.GET_LIST_COURSE_START, getListCourseSaga),
        takeEvery(ElearningTypes.GET_ITEM_COURSE_START, getItemCourseSaga),
        takeEvery(ElearningTypes.DELETE_COURSE_START, deleteCourseSaga),
        takeEvery(ElearningTypes.POST_COURSE_START, postCourseSaga),
        takeEvery(ElearningTypes.UPDATE_COURSE_START, updateCourseSaga)
    ]);
}