import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
//import { createHashHistory } from 'history';

import configureStore from './redux/configureStore';
import * as actions from './redux/actions';
import PrivateRoute from './containers/Routing/PrivateRoute';


import Theme from './component/UI/MUiTheme/Theme'


const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'>Loading</div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./component/Layout/CoreUI/DefaultLayout'));
const LayoutPortal = React.lazy(() => import('./component/ElearningLayout/components/Layout/LayoutApp'));
// Pages
const Login = React.lazy(() => import('./containers/Auth/Login'));
const Logout = React.lazy(() => import('./containers/Auth/Logout'));
const LogoutPortal = React.lazy(() => import('./containers/Auth/LogoutPortal.jsx'));
const LoginElearning = React.lazy(() => import('./containers/Auth/LoginElearning'));

const App = (props) => {

  useEffect(() => {
       configureStore.dispatch(actions.checkUserSession());
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <Router>
        <Suspense fallback={loading}>
          <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/login-portail" component={LoginElearning} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/logout-portail" component={LogoutPortal} />
            <PrivateRoute moduleFailRedirect='/login-portail'  path="/portail" name="Portail"  component={LayoutPortal} /> 
            <PrivateRoute moduleFailRedirect='/login'  path="/admin" name="Dashboard"  component={DefaultLayout} /> 
             <Route component={Login} />
    {/*       <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />  */}
        
          </Switch>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}

export default App;