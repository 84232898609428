import { AdminUserTypes } from '../../actions/actions.types';

const initialState = {
    listAdmin: [],
    itemAdmin: null,
    itemAdminEdit: null,
    loading: true,
    errorEdit: null,
    error: null   
};

export default function (state = initialState, action) {
    const { type, payload } = action;
   
    switch (type) {
        case AdminUserTypes.GET_ITEM_ADMIN_SUCCESS:
            return {
                ...state,
                itemAdmin: payload,
                loading: false
            };
        case AdminUserTypes.EDIT_SUCCESS:
            return {
                ...state,
                itemAdminEdit: payload,
                errorEdit: null,
                loading: false
            };
        case AdminUserTypes.GET_LIST_ADMIN_SUCCESS:
            return {
                ...state,
                listAdmin: payload,
                loading: false
            };
        case AdminUserTypes.EDIT_FAILURE:
            return {
                ...state,
                errorEdit: payload,
                loading: false
            };
        case AdminUserTypes.DELETE_ADMIN_SUCCESS:
                return {
                    ...state,
                    listAdmin: state.listAdmin.filter((idAdmin) => idAdmin !== payload),
                    loading: false
                };
        case AdminUserTypes.GET_LIST_ADMIN_FAILURE:
        case AdminUserTypes.GET_ITEM_ADMIN_FAILURE:
        //don't reset listAdmin or itemAdmin for to avoid loose data in screen when error happen
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }

}