import { lazy } from 'react';


export const LINK_PORTAL_DASHBOARD = '/portail/dashboard';
const PortalDashBoard = lazy(() => import('./containers/Elearning/generale/dashboard-all'));


const DisplayDomains = lazy(() => import('./containers/Elearning/generale/display-domain'));
export const LINK_PORTAL_DOMAIN = '/portail/domaines';

const DisplayProgram = lazy(() => import('./containers/Elearning/generale/display-program'));
export const LINK_PORTAL_PROGRAM = '/portail/programmes';

const DisplaySubDomains = lazy(() => import('./containers/Elearning/generale/display-subdomain'));
export const LINK_PORTAL_SUB_DOMAIN = '/portail/sous-domaine/:idondomain';
export const LINK_PORTAL_SUB_DOMAIN_SHARE = '/portail/sous-domaine';

const DisplayDocuments = lazy(() => import('./containers/Elearning/generale/display-documents'));
export const LINK_PORTAL_DOCUMENT = '/portail/documents/:idsubdomain';
export const LINK_PORTAL_DOCUMENT_SHARE = '/portail/documents';

const DisplayCourse = lazy(() => import('./containers/Elearning/generale/display-course'));
export const LINK_PORTAL_COURSE = '/portail/cours/liste-cours/:idprogram';
export const LINK_PORTAL_COURSE_SHARE = '/portail/cours/liste-cours';


export const LINK_TEACHER_DASHBOARD = '/portail/enseignant/tableau-de-bord';
const DashBoardTeacher = lazy(() => import('./containers/Elearning/portal/dashboard-teacher'));

const ListProgram= lazy(() => import('./containers/Elearning/portal/list-program'));
export const LINK_LIST_PROGRAM = '/portail/enseignant/cours/liste-programme';
export const LINK_PORTAL_LIST_PROGRAM_UE = '/portail/:context/cours/liste-programme-ue/:idue';
export const LINK_PORTAL_LIST_PROGRAM_UE_SHARE = '/portail/enseignant/cours/liste-programme-ue';

const CreateProgram= lazy(() => import('./containers/Elearning/portal/create-program'));
export const LINK_CREATE_PROGRAM = '/portail/enseignant/cours/creation-programme';
export const LINK_PORTAL_CREATE_PROGRAM_UE = '/portail/:context/cours/creation-programme-ue/:idue'; 
export const LINK_PORTAL_CREATE_PROGRAM_UE_SHARE = '/portail/enseignant/cours/creation-programme-ue'; 


const ListCourse = lazy(() => import('./containers/Elearning/portal/list-course'));
export const LINK_LIST_COURSE = '/portail/enseignant/cours/liste-cours/:idprogram';
export const LINK_LIST_COURSE_SHARE = '/portail/enseignant/cours/liste-cours';

export const LINK_PORTAL_LIST_COURSE_UE = '/portail/:context/cours/liste-cours-ue/:idue';
export const LINK_PORTAL_LIST_COURSE_UE_SHARE = '/portail/enseignant/cours/liste-cours-ue';



const CreateCourse = lazy(() => import('./containers/Elearning/portal/create-course'));
const CreateCourseNoProgram = lazy(() => import('./containers/Elearning/portal/create-course-noprogram'));
export const LINK_CREATE_COURSE = '/portail/enseignant/cours/creation-cours/:typecourse/:idprogram';

export const LINK_PORTAL_CREATE_COURSE_NOPROGRAM_UE = '/portail/:context/cours/creation-cours-ue/:idue/:typecourse';
export const LINK_PORTAL_CREATE_COURSE_SHARE_UE = '/portail/enseignant/cours/creation-cours-ue';

export const LINK_CREATE_COURSE_NOPROGRAM = '/portail/enseignant/cours/creation-cours/:typecourse';
export const LINK_CREATE_COURSE_SHARE = '/portail/enseignant/cours/creation-cours';

const ListParcoursTeacher = lazy(() => import('./containers/Elearning/portal/list-parcours-teacher'));
export const LINK_LIST_PARCOURS_UE = '/portail/enseignant/liste-parcours-ue';

const ListUeStudent = lazy(() => import('./containers/Elearning/generale/display-ue'));
export const LINK_LIST_STUDENT_UE = '/portail/etudiant/liste-parcours-ue';

const ListProgramCourseUe = lazy(() => import('./containers/Elearning/generale/display-program-course'));
export const LINK_LIST_PROGRAM_COURSE_UE = '/portail/etudiant/liste-cours-module/:idue';
export const LINK_LIST_PROGRAM_COURSE_UE_SHARE = '/portail/etudiant/liste-cours-module';



const routesPortal = [
  { path: LINK_PORTAL_DASHBOARD, component: PortalDashBoard, name: "Tableau de board" , module: 'ADMINISTRATEUR'},
    { path: LINK_TEACHER_DASHBOARD, component: DashBoardTeacher, name: "Tableau de bord" , module: 'ADMINISTRATEUR'},
    { path: LINK_LIST_PROGRAM, component: ListProgram, name: "Liste programme" , module: 'ADMINISTRATEUR'},
    { path: LINK_CREATE_PROGRAM, component: CreateProgram, name: "Création programme" , module: 'ADMINISTRATEUR'},
    { path: LINK_LIST_COURSE, component: ListCourse, name: "Liste des cours" , module: 'ADMINISTRATEUR'},
    { path: LINK_LIST_COURSE_SHARE, component: ListCourse, name: "Liste des cours" , module: 'ADMINISTRATEUR'},
    { path: LINK_CREATE_COURSE, component: CreateCourse, name: "Création de cours" , module: 'ADMINISTRATEUR'},
    { path: LINK_CREATE_COURSE_NOPROGRAM, component: CreateCourseNoProgram, name: "Création de cours" , module: 'ADMINISTRATEUR'},
    { path: LINK_CREATE_COURSE, component: CreateCourse, name: "Création de cours" , module: 'ADMINISTRATEUR'},
    { path: LINK_PORTAL_SUB_DOMAIN, component: DisplaySubDomains, name: "Liste sous domaine" , module: 'ADMINISTRATEUR'},
    { path: LINK_PORTAL_DOCUMENT, component: DisplayDocuments, name: "Liste documents" , module: 'ADMINISTRATEUR'},
    { path: LINK_PORTAL_COURSE, component: DisplayCourse, name: "Liste des cours" , module: 'ADMINISTRATEUR'},
    { path: LINK_PORTAL_COURSE_SHARE, component: DisplayCourse, name: "Liste des cours" , module: 'ADMINISTRATEUR'},
    { path: LINK_PORTAL_DOMAIN, component: DisplayDomains, name: "Liste des themes" , module: 'ADMINISTRATEUR'},
    { path: LINK_PORTAL_PROGRAM, component: DisplayProgram, name: "Liste des themes" , module: 'ADMINISTRATEUR'},
    { path: LINK_LIST_PARCOURS_UE, component: ListParcoursTeacher, name: "Liste des themes" , module: 'ADMINISTRATEUR'},
    { path: LINK_PORTAL_LIST_COURSE_UE, component: ListCourse, name: "Liste des cours" , module: 'ADMINISTRATEUR'},
    { path: LINK_PORTAL_CREATE_COURSE_NOPROGRAM_UE, component: CreateCourseNoProgram, name: "Création des cours" , module: 'ADMINISTRATEUR'}, //
    { path: LINK_PORTAL_LIST_PROGRAM_UE, component: ListProgram, name: "Liste programme" , module: 'ADMINISTRATEUR'},  
    { path: LINK_PORTAL_CREATE_PROGRAM_UE, component: CreateProgram, name: "Création programme" , module: 'ADMINISTRATEUR'}, 
    { path: LINK_LIST_STUDENT_UE, component: ListUeStudent, name: "Mes modules" , module: 'ADMINISTRATEUR'},   //
    { path: LINK_LIST_PROGRAM_COURSE_UE, component: ListProgramCourseUe, name: "Mes modules" , module: 'ADMINISTRATEUR'},
  ];


  export default routesPortal;