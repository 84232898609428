import { AdminUserTypes } from '../actions.types';

export const getListAdminStart = () =>({
    type: AdminUserTypes.GET_LIST_ADMIN_START
});

export const getListAdminSuccess = (listAdminUser) =>({
    type: AdminUserTypes.GET_LIST_ADMIN_SUCCESS,
    payload: listAdminUser
});

export const getListAdminFailure = (error) =>({
    type: AdminUserTypes.GET_LIST_ADMIN_FAILURE,
    payload: error
});

export const getItemAdminStart = (id) =>({
    type: AdminUserTypes.GET_ITEM_ADMIN_START,
    payload: id
});

export const getItemAdminSuccess = (itemAdminUser) =>({
    type: AdminUserTypes.GET_ITEM_ADMIN_SUCCESS,
    payload: itemAdminUser
});

export const getItemAdminFailure = (error) =>({
    type: AdminUserTypes.GET_ITEM_ADMIN_FAILURE,
    payload: error
});

export const postAdminStart = (formPostData, history) => ({
    type: AdminUserTypes.POST_ADMIN_START,
    payload: {formPostData, history}
});

export const updateAdminStart = (formUpdateData, id,  typeEdit, history) => ({
    type: AdminUserTypes.UPDATE_ADMIN_START,
    payload: { formUpdateData, id, typeEdit, history}
});

export const editSuccess = (dataEdit) => ({
    type: AdminUserTypes.EDIT_SUCCESS,
    payload: dataEdit
});

export const editFailure = (error) => ({
    type: AdminUserTypes.EDIT_FAILURE,
    payload: error
});

export const deleteAdminStart = (id) =>({
    type: AdminUserTypes.DELETE_ADMIN_START,
    payload: id
});

export const deleteAdminSuccess = (id) =>({
    type: AdminUserTypes.DELETE_ADMIN_SUCCESS,
    payload: id
});