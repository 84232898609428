import { AuthActionTypes } from '../actions.types';

export const signInStart = (username, password) => ({
        type: AuthActionTypes.SIGN_IN_START,
        payload: { username, password }
    });

export const signInSuccess = (token) => ({
        type: AuthActionTypes.SIGN_IN_SUCCESS,
        payload: token
});

export const signInFailure = (error) => ({
        type: AuthActionTypes.SIGN_IN_FAILURE,
        payload: error
});

export const signOutStart = () => ({
    type: AuthActionTypes.SIGN_OUT_START
});

export const signOutSuccess = () => ({
    type: AuthActionTypes.SIGN_OUT_SUCCESS
});

export const signOutFailure = (error) => ({
    type: AuthActionTypes.SIGN_OUT_FAILURE,
    payload: error 
});

export const checkUserSession = () => ({
    type: AuthActionTypes.CHECK_USER_SESSION
})

export const getUserSession = (userProfil) => ({
    type: AuthActionTypes.GET_USER_SESSION,
    payload: userProfil
})

export const checkTimeoutSession = expirationTime => {
    return {
      type: AuthActionTypes.AUTH_CHECK_TIMEOUT,
      expirationTime: expirationTime
    };
  };

export const getUserSessionFail = () => ({
    type: AuthActionTypes.GET_USER_SESSION_FAIL
})