import { lazy } from 'react';
import {PeopleOutlineTwoTone, Language, CastForEducation, Description} from '@material-ui/icons';


/**
 * Admin User Component
 */
export const LINK_GESTION_ADMIN_MODULE = '/admin/gestion-admin';
const ListAdministrator = lazy(() => import('./containers/AdminUser/list-admin-user'));
export const LINK_LIST_ADMIN = '/admin/gestion-admin/list-administrateur';

const CreateAdministrator = lazy(() => import('./containers/AdminUser/create-admin-user'));
export const LINK_CREATE_ADMIN = '/admin/gestion-admin/creation-administrateur';

const EditAdministrator = lazy(() => import('./containers/AdminUser/edit-admin-identity'));
export const LINK_EDIT_ADMIN = '/admin/gestion-admin/modifier-identite-administrateur/:iduser';
//edit link to share, add parameter in programmatically
export const LINK_EDIT_ADMIN_SHARE = '/admin/gestion-admin/modifier-identite-administrateur';

/**
 * Website Component
 */
const CreateDomain = lazy(() => import('./containers/WebSite/create-domain'));
export const LINK_CREATE_DOMAIN = '/admin/site-web/creation-domaine';

const EditDomain = lazy(() => import('./containers/WebSite/edit-domain')); 
export const LINK_EDIT_DOMAIN = '/admin/site-web/modifier-domaine/:iddomain';
//edit link to share, add parameter in programmatically
export const LINK_EDIT_DOMAIN_SHARE = '/admin/site-web/modifier-domaine';

const ListDomain = lazy(() => import('./containers/WebSite/list-domain'));
export const LINK_LIST_DOMAIN = '/admin/site-web/list-domaine';

//const DetailDomain = lazy(() => import('./containers/AdminUser/edit-admin-identity')); // to  implement
export const LINK_DETAIL_DOMAIN = '/admin/site-web/detail-domaine/:iddomain';
export const LINK_DETAIL_DOMAIN_SHARE = '/admin/site-web/detail-domaine';

const ListPageDomain = lazy(() => import('./containers/WebSite/list-page'));
export const LINK_LIST_PAGE_DOMAIN = '/admin/site-web/list-page/:iddomain';
export const LINK_LIST_PAGE_DOMAIN_SHARE = '/admin/site-web/list-page';

const CreatePage = lazy(() => import('./containers/WebSite/create-page'));
export const LINK_CREATE_PAGE = '/admin/site-web/creation-page/:iddomain';
export const LINK_CREATE_PAGE_SHARE = '/admin/site-web/creation-page'

const AddContentPage = lazy(() => import('./containers/WebSite/add-content-page'));
export const LINK_ADD_CONTENT_PAGE = '/admin/site-web/contenu/:typepage/:idpage';
export const LINK_ADD_CONTENT_PAGE_SHARE = '/admin/site-web/contenu';

const ListInstitution = lazy(() => import('./containers/Scolar/formation/list-institution'));
export const LINK_LIST_INSTITUTION = '/admin/scolarite/liste-etablissement';

const EditInstitution = lazy(() => import('./containers/Scolar/formation/create-institution'));
export const LINK_ADD_INSTITUTION = '/admin/scolarite/creer-etablissement';

const ListSpeciality = lazy(() => import('./containers/Scolar/formation/list-speciality'));
export const LINK_LIST_SPECIALITY = '/admin/scolarite/liste-parcours/:idinstitution';
export const LINK_LIST_SPECIALITY_SHARE = '/admin/scolarite/liste-parcours';

const CreateSpeciality = lazy(() => import('./containers/Scolar/formation/create-speciality'));
export const LINK_CREATE_SPECIALITY = '/admin/scolarite/creation-parcours/:idinstitution';
export const LINK_CREATE_SPECIALITY_SHARE = '/admin/scolarite/creation-parcours'

const ListDegree = lazy(() => import('./containers/Scolar/formation/list-degree'));
export const LINK_LIST_DEGREE = '/admin/scolarite/liste-diplome';

const CreateDegree = lazy(() => import('./containers/Scolar/formation/create-degree'));
export const LINK_CREATE_DEGREE = '/admin/scolarite/creation-diplome';

const ListGrade = lazy(() => import('./containers/Scolar/formation/list-grade'));
export const LINK_LIST_GRADE = '/admin/scolarite/liste-niveau/:idspeciality';
export const LINK_LIST_GRADE_SHARE = '/admin/scolarite/liste-niveau';

const CreateGrade = lazy(() => import('./containers/Scolar/formation/create-grade'));
export const LINK_CREATE_GRADE = '/admin/scolarite/creation-niveau/:idspeciality';
export const LINK_CREATE_GRADE_SHARE = '/admin/scolarite/creation-niveau';

const ListStudent = lazy(() => import('./containers/Scolar/profil/list-student'));
export const LINK_LIST_STUDENT = '/admin/scolarite/liste-etudiant/:idgrade';
export const LINK_LIST_STUDENT_SHARE = '/admin/scolarite/liste-etudiant';

const CreateStudent = lazy(() => import('./containers/Scolar/profil/create-student'));
export const LINK_CREATE_STUDENT = '/admin/scolarite/creation-etudiant/:idgrade';
export const LINK_CREATE_STUDENT_SHARE = '/admin/scolarite/creation-etudiant';

const ListTeacher = lazy(() => import('./containers/Scolar/profil/list-teacher'));
export const LINK_LIST_TEACHER = '/admin/scolarite/liste-enseignant';

const CreateTeacher = lazy(() => import('./containers/Scolar/profil/create-teacher'));
export const LINK_CREATE_TEACHER = '/admin/scolarite/creation-enseignant';

const ListOnDomain = lazy(() => import('./containers/Elearning/ged/list-on-domain'));
export const LINK_LIST_ON_DOMAIN = '/admin/scolarite/ged/liste-domaine';
const CreateOnDomain = lazy(() => import('./containers/Elearning/ged/create-on-domain'));
export const LINK_CREATE_ON_DOMAIN = '/admin/scolarite/ged/création-domaine';

const ListSubDomain = lazy(() => import('./containers/Elearning/ged/list-sub-domain'));
export const LINK_LIST_SUB_DOMAIN = '/admin/scolarite/ged/liste-sous-domaine/:idondomain';
export const LINK_LIST_SUB_DOMAIN_SHARE = '/admin/scolarite/ged/liste-sous-domaine';

const CreateSubDomain = lazy(() => import('./containers/Elearning/ged/create-sub-domain'));
export const LINK_CREATE_SUB_DOMAIN = '/admin/scolarite/ged/creer-sous-domaine/:idondomain';
export const LINK_CREATE_SUB_DOMAIN_SHARE = '/admin/scolarite/ged/creer-sous-domaine'

const ListDocument = lazy(() => import('./containers/Elearning/ged/list-document'));
export const LINK_LIST_DOCUMENT = '/admin/scolarite/ged/liste-document/:idsubdomain';
export const LINK_LIST_DOCUMENT_SHARE = '/admin/scolarite/ged/liste-document';

const CreateDocument = lazy(() => import('./containers/Elearning/ged/create-document'));
export const LINK_CREATE_DOCUMENT = '/admin/scolarite/ged/create-document/:typedocument/:idsubdomain';
export const LINK_CREATE_DOCUMENT_SHARE = '/admin/scolarite/ged/create-document';

const ListUe = lazy(() => import('./containers/Scolar/formation/list-ue'));
export const LINK_LIST_UE = '/admin/scolarite/liste-ue/:idgrade';
export const LINK_LIST_UE_SHARE = '/admin/scolarite/liste-ue';

 const CreateUe = lazy(() => import('./containers/Scolar/formation/create-ue'));
export const LINK_CREATE_UE = '/admin/scolarite/creation-ue/:idgrade';
export const LINK_CREATE_UE_SHARE = '/admin/scolarite/creation-ue'; 

const ListCourseUe = lazy(() => import('./containers/Elearning/portal/list-course'));
export const LINK_LIST_COURSE_UE = '/admin/:context/cours/liste-cours-ue/:idue';
export const LINK_LIST_COURSE_UE_SHARE = '/admin/scolarite/cours/liste-cours-ue';

const CreateCourseNoProgramUe = lazy(() => import('./containers/Elearning/portal/create-course-noprogram'));
export const LINK_SCOL_CREATE_COURSE_UE = '/admin/scolarite/cours/creation-cours/:idue/:typecourse/:idprogram';

export const LINK_SCOL_CREATE_COURSE_NOPROGRAM_UE = '/admin/:context/cours/creation-cours/:idue/:typecourse';
export const LINK_SCOL_CREATE_COURSE_SHARE_UE = '/admin/scolarite/cours/creation-cours';

const ListProgramUe= lazy(() => import('./containers/Elearning/portal/list-program'));
export const LINK_LIST_PROGRAM_UE = '/admin/:context/cours/liste-programme-ue/:idue';
export const LINK_LIST_PROGRAM_UE_SHARE = '/admin/scolarite/cours/liste-programme-ue';
 const CreateProgramUe= lazy(() => import('./containers/Elearning/portal/create-program'));
export const LINK_SCOL_CREATE_PROGRAM_UE = '/admin/:context/cours/creation-programme-ue/:idue'; 
export const LINK_SCOL_CREATE_PROGRAM_UE_SHARE = '/admin/scolarite/cours/creation-programme-ue'; 

const ListScolCourse = lazy(() => import('./containers/Elearning/portal/list-course'));
export const LINK_SCOL_LIST_COURSE = '/admin/:context/cours/liste-cours/:idprogram';
export const LINK_SCOL_LIST_COURSE_SHARE = '/admin/scolarite/cours/liste-cours';

const CreateScolCourse = lazy(() => import('./containers/Elearning/portal/create-course'));
export const LINK_SCOL_CREATE_COURSE = '/admin/:context/cours/creation-cours-program/:typecourse/:idprogram';
export const LINK_SCOL_CREATE_COURSE_SHARE = '/admin/scolarite/cours/creation-cours-program';

const ListMessage = lazy(() => import('./containers/Message/list-message'));
export const LINK_LIST_ALL_MESSAGE = '/admin/scolarite/liste-message';



const Dashboard = lazy(() => import('./containers/Dashboard/dashboard-app'));
const Scolarite = () => 'Scolarite';

/**
 * LINK USER ADMIN
 */
export const LINK_DASHBOARD = '/admin/dashboard';
export const LINK_LOGOUT = '/logout';



// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: LINK_DASHBOARD, name: 'Home' , module: 'ADMINISTRATEUR', icon: PeopleOutlineTwoTone},
  { path: LINK_DASHBOARD, name: 'Dashboard', component: Dashboard , module: 'Tableau de bord', icon: PeopleOutlineTwoTone},
  { path: LINK_GESTION_ADMIN_MODULE, exact: true,  name: 'ADMINISTRATEURS' },
  { path: LINK_LIST_ADMIN, name: 'Liste administrateur', component: ListAdministrator, module: 'ADMINISTRATEUR', icon: PeopleOutlineTwoTone },
  { path: LINK_CREATE_ADMIN, name: 'Création administrateur', component: CreateAdministrator , module: 'ADMINISTRATEUR', icon: PeopleOutlineTwoTone},
  { path: LINK_EDIT_ADMIN, name: 'Modifier administrateur', component: EditAdministrator , module: 'ADMINISTRATEUR', icon: PeopleOutlineTwoTone},
  { path: LINK_CREATE_DOMAIN, name: 'Création site', component: CreateDomain , module: 'SITE WEB', icon: Language},
  { path: LINK_LIST_DOMAIN,  name: 'Liste site', component: ListDomain , module: 'SITE WEB', icon: Language},
  { path: LINK_EDIT_DOMAIN, name: 'Modifier site', component: EditDomain , module: 'SITE WEB', icon: Language},
  { path: LINK_LIST_PAGE_DOMAIN, name: 'Liste page', component: ListPageDomain, module:  'SITE WEB', icon: Language },
  { path: LINK_CREATE_PAGE, name: 'Creation page', component: CreatePage , module: 'SITE WEB', icon: Language},
  { path: LINK_ADD_CONTENT_PAGE, name: 'Contenu page', component: AddContentPage , module: 'SITE WEB', icon: Language},
  { path: LINK_LIST_INSTITUTION, name: 'Liste établissement', component: ListInstitution , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_ADD_INSTITUTION, name: 'Creer établissement', component: EditInstitution , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_LIST_SPECIALITY, name: 'Liste parcours', component: ListSpeciality , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_CREATE_SPECIALITY, name: 'Créer parcours', component: CreateSpeciality , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_LIST_DEGREE, name: 'Liste diplome', component: ListDegree , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_CREATE_DEGREE, name: 'Création diplome', component: CreateDegree , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_LIST_GRADE, name: 'Liste niveau', component: ListGrade , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_CREATE_GRADE, name: 'Création niveau', component: CreateGrade , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_LIST_STUDENT, name: 'Liste étudiant', component: ListStudent , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_CREATE_STUDENT, name: 'Création étudiant', component: CreateStudent , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_LIST_TEACHER, name: 'Liste enseignant', component: ListTeacher , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_CREATE_TEACHER, name: 'Création enseignant', component: CreateTeacher , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_LIST_ON_DOMAIN, name: 'Liste domaine', component: ListOnDomain , module: 'SCOLARITE', icon: Description},
  { path: LINK_CREATE_ON_DOMAIN, name: 'Céation domaine', component: CreateOnDomain , module: 'SCOLARITE', icon: Description},
  { path: LINK_LIST_SUB_DOMAIN, name: 'Liste sous domaine', component: ListSubDomain , module: 'SCOLARITE', icon: Description},
  { path: LINK_CREATE_SUB_DOMAIN, name: 'Création sous domaine', component: CreateSubDomain , module: 'SCOLARITE', icon: Description},
  { path: LINK_LIST_DOCUMENT, name: 'Liste document', component: ListDocument , module: 'SCOLARITE', icon: Description},
  { path: LINK_CREATE_DOCUMENT, name: 'Création document', component: CreateDocument , module: 'SCOLARITE', icon: Description},
  { path: LINK_LIST_UE, name: 'Liste ue (Module)', component: ListUe , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_CREATE_UE, name: 'Création ue (Module)', component: CreateUe , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_LIST_COURSE_UE, name: 'Liste cours', component: ListCourseUe , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_SCOL_CREATE_COURSE_NOPROGRAM_UE, name: 'Liste cours', component: CreateCourseNoProgramUe , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_LIST_PROGRAM_UE, name: 'Liste programme - groupe cours', component: ListProgramUe , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_SCOL_CREATE_PROGRAM_UE, name: 'Création programme - groupe cours', component: CreateProgramUe , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_SCOL_LIST_COURSE, name: 'Liste cours', component: ListScolCourse , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_SCOL_CREATE_COURSE, name: 'Création cours', component: CreateScolCourse , module: 'SCOLARITE', icon: CastForEducation},
  { path: LINK_LIST_ALL_MESSAGE, name: 'Liste message', component: ListMessage , module: 'MESSAGE', icon: CastForEducation},
  
  
  { path: '/admin/base/tabs', name: 'Tabs', component: Scolarite, module: 'ADMINISTRATEUR', icon: PeopleOutlineTwoTone }
];

export default routes;
