import { WebSiteTypes } from '../actions.types';


export const getListDomainStart = () => ({
    type: WebSiteTypes.GET_LIST_DOMAIN_START
});

export const getListDomainSuccess = (listDomain) =>({
    type: WebSiteTypes.GET_LIST_DOMAIN_SUCCESS,
    payload: listDomain
});

export const getListDomainFailure = (error) =>({
    type: WebSiteTypes.GET_LIST_DOMAIN_FAILURE,
    payload: error
});

export const getItemDomainStart = (id) => ({
    type: WebSiteTypes.GET_ITEM_DOMAIN_START,
    payload: id
});

export const getItemDomainSuccess = (itemDomain) => ({
    type: WebSiteTypes.GET_ITEM_DOMAIN_SUCCESS,
    payload: itemDomain
});

export const getItemDomainFailure = (error) => ({
    type: WebSiteTypes.GET_ITEM_DOMAIN_FAILURE,
    payload: error
});

export const deleteDomainStart = (id) => ({
    type: WebSiteTypes.DELETE_DOMAIN_START,
    payload: id
});

export const deleteDomainSuccess = (id) => ({
    type: WebSiteTypes.DELETE_DOMAIN_SUCCESS,
    payload: id
});

export const postDomainStart = (formData, history) => ({
    type: WebSiteTypes.POST_DOMAIN_START,
    payload: {formData, history}
});

export const updateDomainStart = (id, formData, history) => ({
    type: WebSiteTypes.UPDATE_DOMAIN_START,
    payload: { id, formData, history }
});

export const editDomainSuccess = (domainEdit) => ({
    type: WebSiteTypes.EDIT_DOMAIN_SUCCESS,
    payload: domainEdit
});

export const editDomainFailure = (error) => ({
    type: WebSiteTypes.EDIT_DOMAIN_FAILURE,
    payload: error
});


export const getListPageStart = () => ({
    type: WebSiteTypes.GET_LIST_PAGE_START
});

export const getListPageSuccess = (listPage) =>({
    type: WebSiteTypes.GET_LIST_PAGE_SUCCESS,
    payload: listPage
});

export const getListPageFailure = (error) =>({
    type: WebSiteTypes.GET_LIST_PAGE_FAILURE,
    payload: error
});

export const getItemPageStart = (id, typePage) => ({
    type: WebSiteTypes.GET_ITEM_PAGE_START,
    payload: {id, typePage}
});

export const getItemPageSuccess = (itemPage) => ({
    type: WebSiteTypes.GET_ITEM_PAGE_SUCCESS,
    payload: itemPage
});

export const getItemPageFailure = (error) => ({
    type: WebSiteTypes.GET_ITEM_PAGE_FAILURE,
    payload: error
});

export const postPageStart = (formData, typePage, idDomain, history) => ({
    type: WebSiteTypes.POST_PAGE_START,
    payload: { formData, typePage, history, idDomain }
});

export const updatePageStart = (id, formData, typePage, idDomain, history) => ({
    type: WebSiteTypes.UPDATE_PAGE_START,
    payload: { id, formData, typePage, history,  idDomain}
});

export const editPageSuccess = (pageEdit) => ({
    type: WebSiteTypes.EDIT_PAGE_SUCCESS,
    payload: pageEdit
});

export const editPageFailure = (error) => ({
    type: WebSiteTypes.EDIT_PAGE_FAILURE,
    payload: error
});

export const deletePageStart = (id, typePage) => ({
    type: WebSiteTypes.DELETE_PAGE_START,
    payload: { id, typePage}
});

export const deletePageSuccess = (id) => ({
    type: WebSiteTypes.DELETE_PAGE_SUCCESS,
    payload: id
});

export const deletePageSermonSuccess = (id) => ({
    type: WebSiteTypes.DELETE_PAGE_SERMON_SUCCESS,
    payload: id
});

export const getItemContentStart = (id) => ({
    type: WebSiteTypes.GET_ITEM_CONTENT_START,
    payload: id
});

export const getItemContentSuccess = (itemContent) => ({
    type: WebSiteTypes.GET_ITEM_CONTENT_SUCCESS,
    payload: itemContent
});

export const getItemContentFailure = (error) => ({
    type: WebSiteTypes.GET_ITEM_CONTENT_FAILURE,
    payload: error
});

export const updateContentStart = (id, formData, idDomain, history) => ({
    type: WebSiteTypes.UPDATE_CONTENT_START,
    payload: { id, formData, idDomain, history }
});

export const editContentSuccess = (contentEdit) => ({
    type: WebSiteTypes.EDIT_CONTENT_SUCCESS,
    payload: contentEdit
});

export const editContentFailure = (error) => ({
    type: WebSiteTypes.EDIT_CONTENT_FAILURE,
    payload: error
});

export const getListPageSermonStart = () => ({
    type: WebSiteTypes.GET_LIST_PAGE_SERMON_START
});

export const getListPageSermonSuccess = (listPage) =>({
    type: WebSiteTypes.GET_LIST_PAGE_SERMON_SUCCESS,
    payload: listPage
});

export const getListPageSermonFailure = (error) =>({
    type: WebSiteTypes.GET_LIST_PAGE_SERMON_FAILURE,
    payload: error
});

export const postMetaMediaStart = (formData) => ({
    type: WebSiteTypes.POST_META_MEDIA_START,
    payload: formData
});

export const editMetaMediaSuccess = (metaEdit) => ({
    type: WebSiteTypes.EDIT_META_MEDIA_SUCCESS,
    payload: metaEdit
});

export const editMetaMediaFailure = (error) => ({
    type: WebSiteTypes.EDIT_META_MEDIA_FAILURE,
    payload: error
});

export const getItemMetaMediaStart = (id) => ({
    type: WebSiteTypes.GET_ITEM_META_MEDIA_START,
    payload: id
});

export const getItemMetaMediaSuccess = (itemMeta) => ({
    type: WebSiteTypes.GET_ITEM_META_MEDIA_SUCCESS,
    payload: itemMeta
});

export const getItemMetaMediaFailure = (error) => ({
    type: WebSiteTypes.GET_ITEM_META_MEDIA_FAILURE,
    payload: error
});

export const getListMetaMediaStart = (typeMeta) => ({
    type: WebSiteTypes.GET_LIST_META_MEDIA_START,
    payload: typeMeta
});

export const getListMetaMediaSuccess = (listMeta) => ({
    type: WebSiteTypes.GET_LIST_META_MEDIA_SUCCESS,
    payload: listMeta
});

export const getListMetaMediaFailure = (error) => ({
    type: WebSiteTypes.GET_LIST_META_MEDIA_FAILURE,
    payload: error
});

export const postMetaFileStart = (formData) => ({
    type: WebSiteTypes.POST_META_FILE_START,
    payload: formData
});

export const editMetaFileSuccess = (metaEdit) => ({
    type: WebSiteTypes.EDIT_META_FILE_SUCCESS,
    payload: metaEdit
});

export const editMetaFileFailure = (error) => ({
    type: WebSiteTypes.EDIT_META_FILE_FAILURE,
    payload: error
});

export const getItemMetaFileStart = (id) => ({
    type: WebSiteTypes.GET_ITEM_META_FILE_START,
    payload: id
});

export const getItemMetaFileSuccess = (itemMeta) => ({
    type: WebSiteTypes.GET_ITEM_META_FILE_SUCCESS,
    payload: itemMeta
});

export const getItemMetaFileFailure = (error) => ({
    type: WebSiteTypes.GET_ITEM_META_FILE_FAILURE,
    payload: error
});

export const getListMetaFileStart = (typeMeta) => ({
    type: WebSiteTypes.GET_LIST_META_FILE_START,
    payload: typeMeta
});

export const getListMetaFileSuccess = (listMeta) => ({
    type: WebSiteTypes.GET_LIST_META_FILE_SUCCESS,
    payload: listMeta
});

export const getListMetaFileFailure = (error) => ({
    type: WebSiteTypes.GET_LIST_META_FILE_FAILURE,
    payload: error
});

export const deleteMetaMediaStart = (id) => ({
    type: WebSiteTypes.DELETE_META_MEDIA_START,
    payload: id
});

export const deleteMetaMediaSuccess = (id) => ({
    type: WebSiteTypes.DELETE_META_MEDIA_SUCCESS,
    payload: id
});

export const deleteMetaFileStart = (id) => ({
    type: WebSiteTypes.DELETE_META_FILE_START,
    payload: id
});

export const deleteMetaFileSuccess = (id) => ({
    type: WebSiteTypes.DELETE_META_FILE_SUCCESS,
    payload: id
});

/**
 * get pages by id domain
 * @param {int} id idDomain
 */
export const getPagesDomainStart = (id) => ({
    type: WebSiteTypes.GET_PAGES_DOMAIN_START,
    payload: id
});

export const getPagesDomainSuccess = (listPage) =>({
    type: WebSiteTypes.GET_PAGES_DOMAIN_SUCCESS,
    payload: listPage
});

export const getPagesDomainFailure = (error) =>({
    type: WebSiteTypes.GET_PAGES_DOMAIN_FAILURE,
    payload: error
});

export const getPagesSermonDomainStart = (id) => ({
    type: WebSiteTypes.GET_PAGES_SERMON_DOMAIN_START,
    payload: id
});

export const getPagesSermonDomainSuccess = (listPage) =>({
    type: WebSiteTypes.GET_PAGES_SERMON_DOMAIN_SUCCESS,
    payload: listPage
});

export const getPagesSermonDomainFailure = (error) =>({
    type: WebSiteTypes.GET_PAGES_SERMON_DOMAIN_FAILURE,
    payload: error
});

export const searchPagesSermonStart = (param) => ({
    type: WebSiteTypes.SEARCH_PAGES_SERMON_START,
    payload:  param
});

export const searchPagesSermonSuccess = (listPage) =>({
    type: WebSiteTypes.SEARCH_PAGES_SERMON_SUCCESS,
    payload: listPage
});

export const searchPagesSermonFailure = (error) =>({
    type: WebSiteTypes.SEARCH_PAGES_SERMON_FAILURE,
    payload: error
});

export const searchPagesStart = (param) => ({
    type: WebSiteTypes.SEARCH_PAGES_START,
    payload: param
});

export const searchPagesSuccess = (listPage) =>({
    type: WebSiteTypes.SEARCH_PAGES_SUCCESS,
    payload: listPage
});

export const searchPagesFailure = (error) =>({
    type: WebSiteTypes.SEARCH_PAGES_FAILURE,
    payload: error
});